import { Pass, Sale, AttendanceSummary } from "src/types";
import FormPage, {
  FormTab,
  FormTabRenderer,
  FormTabRendererProps,
} from "../../../../components/forms/table";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "preact/hooks";
import { PersonPassesPanel } from "../../../../routes/door/components/person_passes";
import { db } from "../../../../firebase";
import {
  PersonSalesPanel,
  getItemNames,
} from "../../../../routes/door/components/person_sales";
import { PersonClassAttendancesPanel } from "../../../../routes/door/components/person_attendances";
import { PersonFormDefinition } from "../../../../components/forms/definitions/person";
import {
  getPersonAttendances,
  getPersonPasses,
} from "../../../../utils/firebase";

function AdminPersonPasses(): FormTabRenderer {
  const [personPasses, setPersonPasses] = useState<
    Record<string, Pass[] | undefined>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const personId = props.instance.id;

    if (!(personId in personPasses)) {
      setPersonPasses({ ...personPasses, [personId]: undefined });

      getPersonPasses(personId).then((passes) =>
        setPersonPasses({
          ...personPasses,
          [personId]: passes,
        })
      );
    }

    if (personId in personPasses) {
      const personPassArr = personPasses[personId];
      if (personPassArr) {
        return PersonPassesPanel(personPassArr, { edit: true });
      }
    }
    return <div>Passes tab loading</div>;
  };
}

function AdminPersonClassAttendance(): FormTabRenderer {
  const [personAttendances, setPersonAttendances] = useState<
    Record<string, AttendanceSummary | undefined>
  >({});

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id) {
      return <></>;
    }
    const personId = props.instance.id;

    if (!(personId in personAttendances)) {
      setPersonAttendances({ ...personAttendances, [personId]: undefined });

      getPersonAttendances(personId).then((attendances) =>
        setPersonAttendances({
          ...personAttendances,
          [personId]: attendances,
        })
      );
    }

    if (personId in personAttendances) {
      const personAttendanceArr = personAttendances[personId];
      if (personAttendanceArr) {
        return PersonClassAttendancesPanel(personAttendanceArr);
      }
    }
    return <div>Attendance tab loading</div>;
  };
}

function AdminPersonSales(): FormTabRenderer {
  const [personSales, setPersonSales] = useState<
    Record<string, Sale[] | undefined>
  >({});

  const [itemNames, setItemNames] = useState<Record<string, string>>();

  useEffect(() => {
    if (!itemNames) {
      getItemNames().then((itemNames) => setItemNames(itemNames));
    }
  });

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id || !itemNames) {
      return <></>;
    }
    const personId = props.instance.id;

    if (!(personId in personSales)) {
      setPersonSales({ ...personSales, [personId]: undefined });

      getDocs(
        query(
          collection(db, "sale"),
          where("person", "==", doc(db, "person/" + personId)),
          orderBy("time", "desc"),
          limit(50)
        )
      ).then((snapshot) => {
        const saleRefs = snapshot.docs.map((doc) => doc.data()) as Sale[];
        setPersonSales({ ...personSales, [personId]: saleRefs });
      });
    }

    if (personId in personSales) {
      const personSaleArr = personSales[personId];
      if (personSaleArr) {
        return PersonSalesPanel(personSaleArr, itemNames);
      }
    }
    return <div>Sales tab loading</div>;
  };
}

export default function AdminPerson() {
  const tabs: FormTab = {
    Profile: null,
    Passes: AdminPersonPasses(),
    "Class Attendance": AdminPersonClassAttendance(),
    Sales: AdminPersonSales(),
    // TODO: bookings, once events are in
  };

  return FormPage({
    ...PersonFormDefinition,
    search: true,
    tabs,
  });
}
