import { orderBy } from "firebase/firestore";
import { Announcement, PartialRecord } from "src/types";

import FormPage, { TableFormType } from "../../../components/forms/table";

export default function AdminAnnouncements() {
  const dbCollection = "announcements";
  const displayOrder = [orderBy("show_until", "desc")];

  type TableType = Announcement;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Title", "title"],
    ["Show on Website", "on_website"],
    ["Show in Newsletter", "in_newsletter"],
    ["Show Until", "show_until"],
    ["Announcement text", "body"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    show_until: "datetime-local",
    in_newsletter: "boolean",
    on_website: "boolean",
    body: "textarea",
  };

  const requiredFields = ["show_until", "body"];

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    displayOrder,
    canDelete: true,
  });
}
