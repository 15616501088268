import { ParsedToken } from "firebase/auth";

// Website account management
export interface claimSet {
  person?: string;
  door?: boolean;
  door_public?: boolean;
  editor?: boolean;
  admin?: boolean;
}
export type claimKey = keyof claimSet;
export const claimSetKeys = [
  "person",
  "door",
  "door_public",
  "editor",
  "admin",
];

export const toClaimSet = (token: ParsedToken): claimSet => {
  return Object.fromEntries(
    claimSetKeys.map((claim) => [
      claim,
      claim in token ? token[claim] : undefined,
    ])
  );
};
export const claimSetEquivalent = (left: claimSet, right: claimSet) => {
  if (!(left || right)) {
    return true;
  }
  if ((left && !right) || (right && !left)) {
    return false;
  }
  claimSetKeys.forEach((key) => {
    if (left[key as claimKey] !== right[key as claimKey]) {
      return false;
    }
  });
  return true;
};

export interface loggedInUser {
  name: string | null | undefined;
  email?: string | null;
  emailVerified?: boolean;
  claims: claimSet;
}

export type loginMethod = "Google" | "Local";
