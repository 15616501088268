import {
  ExtractTuples,
  ImportTimes,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
} from "../import";
import { DanceEvent } from "src/types";

const SQL_SCHEMA = `CREATE TABLE \`event\` (
  \`event\` int(11) NOT NULL AUTO_INCREMENT,
  \`date\` date NOT NULL,
  \`description\` varchar(100) NOT NULL,
  \`type\` enum('class','workshop','private','danceparty') NOT NULL DEFAULT 'class',
  \`location\` varchar(100) NOT NULL,
  \`cashbox\` decimal(10,2) NOT NULL,
  \`bookable\` int(1) NOT NULL,
  \`special\` int(1) NOT NULL DEFAULT '0',
  \`term\` int(11) DEFAULT NULL,
  PRIMARY KEY (\`event\`)
)`;

export const EVENTS_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  start: "date",
  description: "str",
  type: "str",
  location: "str", // map to location doc by name
  cashbox: "str", // parseFloat
  bookable: "int", // to bool
  special: "int", //  to bool
  term: "int", // map to term doc
};

// TODO: import classes from before class-nights started
// TODO: import events

export const ExtractEvents = (input: string, lastImports: ImportTimes) => {
  throw "Entry: Not Yet Implemented";
  SchemaCheck(input, SQL_SCHEMA);

  const lastImport = lastImports["event"] || 0;
  console.log({ lastImport });

  return ExtractTuples<DanceEvent>(input, "event", { id: "int", name: "str" });
};

export const ImportEvents = (input: string, lastImports: ImportTimes) =>
  SaveRecords(ExtractEvents(input, lastImports), "event");
