// Import the functions you need from the SDKs you need
import { initializeApp, setLogLevel } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdGeY3H6K6H-LJct8t1AFj-4Saubzw8OA",
  authDomain: "ceroc-website.firebaseapp.com",
  databaseURL:
    "https://ceroc-website-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ceroc-website",
  storageBucket: "ceroc-website.appspot.com",
  messagingSenderId: "199327822941",
  appId: "1:199327822941:web:c9150fb53b91e33d0c89b4",
  measurementId: "G-T6TH49ZTHR",
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});
// const analytics = getAnalytics(firebase);

export const db = getFirestore(firebase);
export const DOOR_PREFIX = ""; // "door-test-";
export const NEW_ENTRY_TOKEN = "add-new";
setLogLevel("info");
