import { Component, h } from "preact";
import { Route, Router, RouterOnChangeArgs } from "preact-router";
import React, { useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth, getRedirectResult } from "firebase/auth";

import Header from "./header";
import adminRoutes from "../routes/admin/routes";

import {
  claimSet,
  claimSetEquivalent,
  ComponentType,
  Controllers,
  loggedInUser,
  menuItemContent,
  pageEntry,
  pageList,
  RouteEntry,
  RouteEntryComponent,
  RouteEntrySub,
  toClaimSet,
} from "../types";

import { Announcements } from "./announcements";
import prerenderData from "../../prerender-urls.json";
import { Footer } from "./footer";
import { ErrorRoute } from "./error";
import { postLoginGoogle } from "../utils/auth";

const FORCE_DOOR_PUBLIC = false;

const subRoutes = {
  Admin: adminRoutes,
} as Record<string, Record<string, RouteEntry>>;

export default class App extends Component {
  pages(pagesList: pageEntry[], currentUser?: loggedInUser): pageList {
    if (currentUser?.claims?.door_public || FORCE_DOOR_PUBLIC) {
      return {
        "/": {
          component: "DoorPublic",
          title: "Door (Barcodes)",
          topLevelApp: true,
          fullscreen: true,
        },
      };
    }

    const viewablePages = pagesList.filter((page) => {
      return (
        page.component &&
        (!page.role ||
          (page.role === "person" && currentUser) ||
          (currentUser?.claims &&
            (currentUser?.claims[page.role] || currentUser?.claims.admin)))
      );
    });
    return Object.fromEntries(viewablePages.map((x) => [x.url, x]));
  }

  headerLinks(pages: pageList) {
    const renderSubRoute = (
      page: string,
      subRoute: Record<string, RouteEntry>
    ) => {
      return Object.entries(subRoute).map(([url, entry]): menuItemContent => {
        if ("subRoute" in entry) {
          return {
            label: entry.title,
            subMenu: renderSubRoute(page + "/" + url, entry.subRoute),
          } as menuItemContent;
        }
        return {
          label: entry.title,
          url: page + "/" + url,
        } as menuItemContent;
      });
    };

    return Object.keys(pages)
      .filter((page) => pages[page].menuOrder)
      .sort((a, b) => (pages[a].menuOrder || 0) - (pages[b].menuOrder || 0))
      .map((page) => {
        const component = pages[page].component;
        if (component in subRoutes) {
          const subRoute = subRoutes[component];
          return {
            label: pages[page].title,
            subMenu: renderSubRoute(page, subRoute),
          } as menuItemContent;
        }
        return { label: pages[page].title, url: page } as menuItemContent;
      });
  }

  addPagesFromFirestore(
    pages: pageList,
    claims: claimSet,
    setPages: React.StateUpdater<pageList>,
    newUser?: loggedInUser
  ) {
    // if editor/admin, then check firestore for any new pages
    if ((claims.admin || claims.editor) && !FORCE_DOOR_PUBLIC) {
      const q = query(collection(db, "html-published"));
      getDocs(q).then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          const url = decodeURIComponent(doc.id);
          if (!(url in pages)) {
            pages[url] = { component: "", title: "", ...doc.data() };
          }
        });
        setPages({ ...pages });
      });
    } else {
      // not editor/admin, so pages are limited - but might have some additional access (e.g. profile page)
      setPages(this.pages(prerenderData as pageEntry[], newUser));
    }
  }

  render() {
    const [currentUser, setCurrentUser] = useState<loggedInUser>();

    const [pages, setPages] = useState<pageList>(
      this.pages(prerenderData as pageEntry[], currentUser)
    );

    const [isDoor, setIsDoor] = useState(
      typeof window !== "undefined" &&
        window.location.pathname.startsWith("/door")
    );

    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user && auth.currentUser) {
        auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            const claimSet = toClaimSet(idTokenResult.claims);
            const changed =
              currentUser?.name !== auth.currentUser?.displayName ||
              !claimSetEquivalent(claimSet, currentUser?.claims || {});
            if (changed) {
              const newUser = {
                name: auth.currentUser?.displayName,
                email: auth.currentUser?.email,
                emailVerified: auth?.currentUser?.emailVerified,
                claims: claimSet,
              };
              setCurrentUser(newUser);
              this.addPagesFromFirestore(pages, claimSet, setPages, newUser);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // not logged in
        setCurrentUser(undefined);
      }
    });

    getRedirectResult(auth).then((result) => result && postLoginGoogle(result));

    if (Object.keys(pages).length === 1) {
      const path = Object.keys(pages)[0];
      const page = pages[path];
      if (page.fullscreen) {
        return Controllers[page.component]({
          path,
          url: page.url || path,
          currentUser,
        });
      }
    }

    const [topLevelPageURLs, publicPageURLs] = Object.keys(pages).reduce(
      (result, url) => {
        result[pages[url].topLevelApp ? 0 : 1].push(url);
        return result;
      },
      [[] as string[], [] as string[]]
    );

    const makeRouter = (paths: string[], inner?: () => h.JSX.Element) => {
      const makeRoute = (
        path: string,
        component: ComponentType,
        title: string,
        html?: HTMLElement | undefined
      ) => {
        const paramedPath = path === "/" ? path : path + "/:rest?";
        return (
          <Route
            path={paramedPath}
            currentUser={currentUser}
            component={component}
            pageTitle={title}
            innerHTML={html}
          />
        );
      };

      return (
        <Router onChange={onRouteChange}>
          {paths.flatMap((path) => {
            if (pages[path].component in subRoutes) {
              const subRoute = subRoutes[pages[path].component];
              return Object.keys(subRoute).map((subPath) => {
                if ("component" in subRoute[subPath]) {
                  const subRouteEntry = subRoute[
                    subPath
                  ] as RouteEntryComponent;
                  return makeRoute(
                    path + "/" + subPath,
                    subRouteEntry.component,
                    subRouteEntry.title
                  );
                }
                // Only allowing a depth of 2; not recursive
                if ("subRoute" in subRoute[subPath]) {
                  const subRouteEntry = subRoute[subPath] as RouteEntrySub;
                  const subsubRoute = subRouteEntry.subRoute;
                  return Object.keys(subsubRoute).map((subSubPath) => {
                    if ("component" in subsubRoute[subSubPath]) {
                      const subRouteEntry = subsubRoute[
                        subSubPath
                      ] as RouteEntryComponent;
                      return makeRoute(
                        path + "/" + subPath + "/" + subSubPath,
                        subRouteEntry.component,
                        subRouteEntry.title
                      );
                    }
                  });
                }
              });
            }

            return makeRoute(
              path,
              Controllers[pages[path].component],
              pages[path].title,
              pages[path].body
            );
          })}
          {inner && <Route path="/:rest?" component={inner} />}
          <ErrorRoute type="404" default />
        </Router>
      );
    };

    const setPageMeta = (path: string) => {
      const page = pages[path];
      if (page && typeof document !== "undefined") {
        document.title =
          (page.title || "Home") + " | Ceroc & Modern Jive Adelaide";
      }
    };

    const onRouteChange = (ev: RouterOnChangeArgs) => {
      setIsDoor(ev.url.startsWith("/door"));
      if (ev.url in pages) {
        setPageMeta(ev.url);
      } else {
        const path = ev.url.replace(/\/$/, "");
        if (path in pages) {
          setPageMeta(ev.url);
        } else {
          setPageMeta("/");
        }
      }
    };

    const publicPageRouter = () => {
      return (
        <React.Fragment>
          <div class="app-body-wrap">
            <div class="app-body">
              <Announcements />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/ceroc-website.appspot.com/o/Ceroc%20Logo.webp?alt=media&token=6fcc4c50-377c-4e73-985e-cbe2479157f2"
                alt="Ceroc & Modern Jive Adelaide logo"
                class="cerocLogo"
              />
              {makeRouter(publicPageURLs)}
            </div>
          </div>
          <Footer links={this.headerLinks(pages)} currentUser={currentUser} />
        </React.Fragment>
      );
    };

    return (
      <div id="app" class={isDoor ? "door" : undefined}>
        <Header
          links={this.headerLinks(pages)}
          currentUser={currentUser}
          heading="Ceroc &amp; Modern Jive Adelaide"
          shortHeading="CMJA: Ceroc Adelaide"
          showAuth={true}
        />
        {makeRouter(topLevelPageURLs, publicPageRouter)}
      </div>
    );
  }
}
