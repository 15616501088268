import {
  DanceEvent,
  FirebaseBackedObject,
  FirebaseValueType,
  PartialRecord,
} from "../../../../types";
import FormPage, { TableFormType } from "../../../../components/forms/table";
import { Timestamp, orderBy } from "firebase/firestore";
import { DEFAULT_START_TIME } from "./classes";

export const AdminEventsDefinition = () => {
  type TableType = DanceEvent;

  const dbCollection = "events";
  const displayOrderFields: (keyof TableType)[] = ["start"];
  const displayOrder = displayOrderFields.map((field) =>
    orderBy(field, "desc")
  );
  const limit = 10;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Start time", "start"],
    ["End", "end"],
    ["Location", "location"],
    ["Short Description (email)", "short_description"],
    ["Longer Description (website)", "description"],
    ["Bookable", "bookable"],
    ["Published on website & newsletter", "is_published"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    start: "datetime-local",
    end: "datetime-local",
    bookable: "boolean",
    description: "textarea",
    is_published: "boolean",
    location: "locations",
  };

  const requiredFields = ["start", "location"];

  const d = new Date();
  const day = d.getDay();
  d.setHours(DEFAULT_START_TIME.hour);
  d.setMinutes(DEFAULT_START_TIME.minute);
  d.setSeconds(0);
  d.setMilliseconds(0);

  const formDefaults: PartialRecord<keyof TableType, FirebaseValueType> = {
    bookable: true,
    start: Timestamp.fromDate(
      // Target Saturday (6), plus 7 to keep %7 positive
      new Date(d.setDate(d.getDate() + ((6 + 7 - day) % 7)))
    ),
  };

  const pagingOffset = (lastDoc: FirebaseBackedObject) => {
    return displayOrderFields.map((field) => (lastDoc as TableType)[field]);
  };

  return {
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    displayOrder,
    limit,
    pagingOffset,
    canDelete: true,
    formDefaults,
  };
};

export default function AdminEvents() {
  return FormPage(AdminEventsDefinition());
}
