import {
  Attendance,
  AttendanceEntry,
  CategoryLookup,
  PaymentMethods,
  Person,
  PersonCategory,
  Sale,
  docLookup,
} from "../../../../types";
import {
  FormTabRenderer,
  FormTabRendererProps,
} from "../../../../components/forms/table";
import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "preact/hooks";
import { db } from "../../../../firebase";
import { AttendancePanel } from "../../../door/components/attendance";
import { getCollection } from "../../../../utils/firebase";
import {
  getItemNames,
  saleItemNamesString,
} from "../../../door/components/person_sales";

// TODO: Class Review tab; cash box, delete/unuse entries/sales; change method for sales

export function AdminAttendance(): FormTabRenderer {
  const [attendances, setAttendances] = useState<
    Record<string, AttendanceEntry[] | undefined>
  >({});
  const [personCategories, setPersonCategories] = useState<CategoryLookup>();

  const resolveAttendance = (
    attendanceRefs: Attendance[],
    personPass: docLookup
  ) => {
    return attendanceRefs.map((attendance) => {
      return {
        ...attendance,
        person: personPass[attendance.person.path],
      } as AttendanceEntry;
    });
  };

  useEffect(() => {
    if (!personCategories) {
      getCollection("categories").then((resultList) =>
        setPersonCategories(
          Object.fromEntries(
            resultList.map((result) => [result.collectionPath, result])
          )
        )
      );
    }
  });

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id || !personCategories) {
      return <></>;
    }
    const eventId = props.instance.id;
    if (!(eventId in attendances)) {
      setAttendances({ ...attendances, [eventId]: undefined });
      const name = `${props.props.dbCollection}/${eventId}/attendance/`;
      getDocs(query(collection(db, name))).then((snapshot) => {
        const attendanceRefs = snapshot.docs.map((doc) =>
          doc.data()
        ) as Attendance[];

        const peopleIds = attendanceRefs
          .filter((attendance) => attendance.person)
          .map((attendance) => attendance.person.id);
        const passIds = attendanceRefs
          .filter((attendance) => attendance.pass?.id)
          .map((attendance) => attendance.pass?.id as string);

        Promise.all([
          ...peopleIds.map((personId) => getDoc(doc(db, "person", personId))),
          ...passIds.map((passId) => getDoc(doc(db, "pass-types", passId))),
        ]).then((personPassArr) => {
          const personPass: docLookup = Object.fromEntries(
            personPassArr.map((personPass) => {
              const data = (personPass.data() as Person) || [];
              return [
                personPass.ref.path,
                {
                  ...data,
                  ...(data["category"]
                    ? {
                        category:
                          personCategories[
                            (
                              data[
                                "category"
                              ] as DocumentReference<PersonCategory>
                            ).path
                          ],
                      }
                    : {}),
                } || {},
              ];
            })
          );
          setAttendances({
            ...attendances,
            [eventId]: resolveAttendance(attendanceRefs, personPass),
          });
        });
      });
    }
    if (eventId in attendances) {
      const eventAttendances = attendances[eventId];
      if (eventAttendances) {
        return AttendancePanel(eventAttendances, true);
      }
    }
    return <div>Attendance tab loading</div>;
  };
}

export function AdminAttendanceSales(): FormTabRenderer {
  const [salesByEvent, setSalesByEvent] = useState<
    Record<string, Sale[] | undefined>
  >({});
  const [itemNames, setItemNames] = useState<Record<string, string>>();

  const resolvePerson = (
    saleRefs: Sale[],
    personLookup: Record<string, string>
  ) => {
    return saleRefs.map((sale) => {
      return {
        ...sale,
        person: sale.person ? personLookup[sale.person.path] : sale.person,
      } as Sale;
    });
  };

  useEffect(() => {
    if (!itemNames) {
      getItemNames().then((itemNames) => setItemNames(itemNames));
    }
  });

  return (props: FormTabRendererProps) => {
    if (!props.instance?.id || !itemNames) {
      return <></>;
    }
    const eventId = props.instance.id;

    if (!(eventId in salesByEvent)) {
      setSalesByEvent({ ...salesByEvent, [eventId]: undefined });

      getDocs(
        query(
          collection(db, "sale"),
          where("event", "==", doc(db, props.props.dbCollection, eventId))
        )
      ).then((snapshot) => {
        const sales = snapshot.docs.map((doc) => doc.data()) as Sale[];
        const peopleIds = sales
          .filter((sale) => sale.person?.id)
          .map((sale) => sale.person?.id || "");

        Promise.all(
          peopleIds.map((personId) => getDoc(doc(db, "person", personId)))
        ).then((personArr) => {
          const personLookup = Object.fromEntries(
            personArr.map((personPass) => [
              personPass.ref.path,
              (personPass.data() || {})["name"],
            ])
          );
          setSalesByEvent({
            ...salesByEvent,
            [eventId]: resolvePerson(sales, personLookup),
          });
        });
      });
    }

    const cashBox = () => {
      // until editable, no point putting this up
      // Cash box amount: $[0.00] [Update] [Cash Box UNDER by $240.00]
      return "";
    };

    const totalsPerMethod: Record<string, number> = {};
    const renderSale = (sale: Sale) => {
      if (sale.method in totalsPerMethod) {
        totalsPerMethod[sale.method] += sale.amount;
      } else {
        totalsPerMethod[sale.method] = sale.amount;
      }

      return (
        <>
          <div class="row">
            <div class="col">
              {saleItemNamesString(sale, itemNames)}
              {sale.person && " -- " + sale.person}
            </div>
            <div class="col align-end">
              {PaymentMethods[sale.method as keyof typeof PaymentMethods]}: $
              {sale.amount}
            </div>
          </div>
          {sale.notes && <div class="row">{sale.notes}</div>}
        </>
      );
    };
    const saleSort = (a: Sale, b: Sale) => {
      if (a.method === b.method) {
        return b.amount - a.amount;
      }
      return a.method.toString().localeCompare(b.method.toString());
    };

    if (eventId in salesByEvent) {
      const eventSales = salesByEvent[eventId];
      if (eventSales) {
        return (
          <div>
            <div class="p-1">
              {cashBox()}
              {eventSales.sort(saleSort).map(renderSale)}
            </div>
            <div class="p-1">
              <strong>Payment Totals:</strong>
              {Object.keys(totalsPerMethod).map((method) => (
                <div>
                  {PaymentMethods[method as keyof typeof PaymentMethods]}: $
                  {totalsPerMethod[method]}
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
    return <div>Sales tab loading</div>;
  };
}
