import { DocumentReference, doc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  CollectionCache,
  ExtractTuples,
  ImportTimes,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
  timeToTimestamp,
} from "../import";
import { Attendance, ClassNight, DanceEvent, Person } from "src/types";
import { EVENTS_SCHEMA } from "./event";
import { ExtractEntry } from "./entry";

const SQL_SCHEMA = `CREATE TABLE \`attendance\` (
  \`attendance\` int(11) NOT NULL AUTO_INCREMENT,
  \`event\` int(11) NOT NULL,
  \`person\` int(11) NOT NULL,
  \`entry\` int(11) DEFAULT NULL,
  \`booked\` int(1) NOT NULL,
  \`present\` int(1) NOT NULL,
  PRIMARY KEY (\`attendance\`),
  UNIQUE KEY \`event\` (\`event\`,\`person\`)
)`;

export const ATTENDANCE_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  event: "int",
  person: "int",
  entry: "int",
  _is_booked: "int",
  is_present: "int",
};

type EventWithClassRef = DanceEvent & {
  classNightRef: DocumentReference<ClassNight>;
};

export const ExtractAttendance = (
  input: string,
  lastImports: ImportTimes,
  collectionCache: CollectionCache
) => {
  SchemaCheck(input, SQL_SCHEMA);

  const lastImport = lastImports["attendance"] || 0;

  const entries = Object.fromEntries(
    ExtractEntry(input, {}).map((tuple) => [tuple.id, tuple])
  );

  if (!(collectionCache && "events" in collectionCache)) {
    throw "Failed to preload class night data";
  }

  const events: Record<number, EventWithClassRef> = Object.fromEntries(
    ExtractTuples<DanceEvent>(input, "event", EVENTS_SCHEMA).map((tuple) => [
      tuple.id,
      {
        ...tuple,
        start: timeToTimestamp(tuple.start),
        classNightRef:
          collectionCache["events"][
            timeToTimestamp(tuple.start).toDate().toISOString().split("T")[0]
          ],
      },
    ])
  );

  type AttendanceWithEvent = Attendance & {
    event: number | DanceEvent;
    entry: number;
  };

  return ExtractTuples<AttendanceWithEvent>(
    input,
    "attendance",
    ATTENDANCE_SCHEMA
  )
    .map((tuple) => {
      return {
        ...tuple,
        date: events[tuple.event as unknown as number].start,
        event: events[tuple.event as unknown as number] as EventWithClassRef,
        person: doc(db, "/person/" + tuple.person) as DocumentReference<Person>,
        pass: entries[tuple.entry]?.id
          ? doc(
              db,
              "/person/" + tuple.person + "/passes/" + entries[tuple.entry].id
            )
          : null,
        pass_name: entries[tuple.entry]?.name || null,
        pass_code: entries[tuple.entry]?.code || null,
        pass_is_multiuse: entries[tuple.entry]?.multiuse || null,
        entry: entries[tuple.entry] || null,
        is_present: !!tuple.is_present,
      };
    })
    .filter(
      (tuple) =>
        tuple.event &&
        tuple.event.classNightRef &&
        tuple.event.start >= lastImport
    );
};

export const ImportAttendance = (
  input: string,
  lastImports: ImportTimes,
  collectionCache: CollectionCache
) => {
  const records = ExtractAttendance(input, lastImports, collectionCache);

  const eventMap = Object.fromEntries(
    records.map((record) => [
      record.id,
      record.event.classNightRef.path + "/attendance",
    ])
  );

  const cleanedRecords = records.map((record) => {
    const { entry: _, event: _2, ...rest } = record;
    return rest;
  }) as (Attendance & { id: string })[];

  const collectionResolver = (record: Attendance & { id: string }) => {
    return eventMap[record.id];
  };
  return SaveRecords(cleanedRecords, "attendance", collectionResolver);
};
