import { Timestamp } from "firebase/firestore";

// like Partial<>, but child objects are Partial as well
export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
    ? Subset<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? Subset<K[attr]> | null | undefined
    : K[attr];
};

export const TimestampMaxYear = 9999;
export const TimestampMax = Timestamp.fromDate(
  new Date(TimestampMaxYear.toString())
);
