import {
  CategoryMap,
  ExtractTuples,
  ImportTimes,
  SaveRecords,
  SchemaCheck,
  SourceMap,
  timeToTimestamp,
} from "../import";
import { Person } from "src/types";
import { makeNGrams } from "../search";
import { Timestamp } from "firebase/firestore";

const SQL_SCHEMA = `CREATE TABLE \`person\` (
  \`person\` int(11) NOT NULL AUTO_INCREMENT,
  \`category\` int(11) NOT NULL,
  \`first_name\` varchar(40) NOT NULL,
  \`full_name\` varchar(100) NOT NULL,
  \`birth\` date DEFAULT NULL,
  \`phone\` varchar(100) NOT NULL,
  \`gender\` enum('M','F') NOT NULL,
  \`postal\` varchar(100) DEFAULT '',
  \`suburb\` varchar(40) DEFAULT '',
  \`postcode\` int(4) DEFAULT NULL,
  \`email\` varchar(100) DEFAULT NULL,
  \`signed_year\` int(11) NOT NULL,
  \`minor_signed\` int(1) NOT NULL,
  \`country\` enum('Australia','NZ','UK','US','Canada','Other') NOT NULL,
  \`source\` int(11) NOT NULL,
  \`attendances\` int(11) DEFAULT '0',
  \`review\` date DEFAULT NULL,
  \`barcode\` varchar(16) DEFAULT NULL,
  \`last_update\` date DEFAULT NULL,
  \`last_attended\` date DEFAULT NULL,
  \`partner_id\` int(11) DEFAULT NULL,
  \`photo_permission\` char(1) NOT NULL DEFAULT '',
  \`pronoun\` varchar(40) NOT NULL,
  PRIMARY KEY (\`person\`),
  KEY \`full_name_index\` (\`full_name\`) USING BTREE,
  KEY \`partner_id\` (\`partner_id\`)
)`;

export const ExtractPeople = (input: string, lastImports: ImportTimes) => {
  SchemaCheck(input, SQL_SCHEMA);

  const lastImport = lastImports["person"] || 0;

  return (
    ExtractTuples<Person>(input, "person", {
      id: "int",
      category: "int",
      other_names: "str",
      name: "str",
      dob: "date",
      phone: "str",
      _gender: "str",
      postal_address: "str",
      suburb: "str",
      postcode: "int",
      email: "str",
      signed: "int",
      minor_signed: "int",
      _country: "str",
      source: "int",
      attendances: "int",
      _review: "date",
      barcode: "str",
      last_update: "date",
      last_attended: "date",
      _partner_id: "int",
      photo_permission: "YN",
      pronoun: "str",
    })
      .map((tuple) => {
        return {
          ...tuple,
          source: SourceMap[tuple.source as unknown as number],
          category: CategoryMap[tuple.category as unknown as number],
          minor_signed: !!tuple.minor_signed,
          photo_permission: !!tuple.photo_permission,
          last_attended: timeToTimestamp(tuple.last_attended),
          last_update: timeToTimestamp(tuple.last_update),
          dob: timeToTimestamp(tuple.dob),
          signed: new Timestamp(
            Date.parse(tuple.signed as unknown as string) / 1000,
            0
          ),
          searchKeywords: [
            tuple.barcode,
            ...makeNGrams(
              ...["name", "other_names"].map(
                (field) => (tuple as never)[field] as string
              )
            ),
          ],
        };
      })
      // last_update is when some fields edited, last_attended is when attandance count changed
      .filter(
        (tuple) =>
          tuple.last_update >= lastImport || tuple.last_attended >= lastImport
      )
  );
};

export const ImportPeople = (input: string, lastImports: ImportTimes) =>
  SaveRecords(ExtractPeople(input, lastImports), "person");
