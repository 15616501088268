import { ExtractTuples, SaveRecords, SchemaCheck } from "../import";
import { Stock } from "src/types";

const SQL_SCHEMA = `CREATE TABLE \`stock\` (
  \`stock\` int(11) NOT NULL AUTO_INCREMENT,
  \`description\` varchar(100) NOT NULL,
  \`price\` float NOT NULL,
  \`discontinued\` int(1) NOT NULL,
  \`barcode\` varchar(40) DEFAULT NULL,
  PRIMARY KEY (\`stock\`)
)`;

export const ExtractStock = (input: string) => {
  SchemaCheck(input, SQL_SCHEMA);

  return ExtractTuples<Stock>(input, "stock", {
    id: "int",
    name: "str",
    price: "float",
    active: "int",
    barcode: "str",
  }).map((tuple) => {
    return {
      ...tuple,
      active: (tuple.active as unknown as number) === 0,
    };
  });
};

export const ImportStock = (input: string) =>
  SaveRecords(ExtractStock(input), "stock");
