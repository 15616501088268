import {
  getDocs,
  query,
  collectionGroup,
  where,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Attendance, AttendanceSummary } from "../../types";
import { TimestampMax } from "../types";

interface GetPersonAttendancesArgs {
  limit?: number;
  summary?: boolean;
  collectionPrefix?: string;
}
export const getPersonAttendances = (
  personId: string,
  args?: GetPersonAttendancesArgs
): Promise<AttendanceSummary> => {
  // TODO: summary (count per year)?
  const collectionPrefix = args?.collectionPrefix || "";
  return new Promise((resolve) => {
    getDocs(
      query(
        collectionGroup(db, collectionPrefix + "attendance"),
        where("person", "==", doc(db, collectionPrefix + "person/" + personId)),
        where("date", "<", TimestampMax),
        orderBy("date", "desc"),
        limit(args?.limit || 20)
      )
    ).then((snapshot) => {
      // TODO: counts, if args?.summary
      resolve({
        recent: snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            event_ref: doc.ref.parent.parent,
            ...doc.data(),
          };
        }) as Attendance[],
      });
    });
  });
};
