import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "preact-router/match";
import React, { useState } from "preact/compat";

import style from "./style.scss";

import { loggedInUser, menuItemContent, menuItemSet } from "../../types";
import { Auth } from "../auth";
import SubMenu from "./submenu";

interface Props {
  links: menuItemSet;
  currentUser?: loggedInUser;
  heading?: string;
  shortHeading?: string;
  showAuth?: boolean;
}

const Header = ({
  links,
  currentUser,
  heading,
  shortHeading,
  showAuth = true,
}: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const renderNonmobileLink = (entry: menuItemContent) => {
    if ("url" in entry) {
      return (
        <Link activeClassName="active" href={entry.url}>
          {entry.label}
        </Link>
      );
    } else if (entry.subMenu) {
      return <SubMenu label={entry.label} entries={entry.subMenu} />;
    }
  };

  const renderMobileSubLink = (entry: menuItemContent) => {
    if ("url" in entry) {
      return (
        <Link
          activeClassName="active"
          href={entry.url}
          onClick={() => setMenuOpen(false)}
        >
          {entry.label}
        </Link>
      );
    } else if (entry.subMenu) {
      return (
        <SubMenu
          label={entry.label}
          entries={entry.subMenu}
          onClose={() => setMenuOpen(false)}
        />
      );
    }
  };

  const renderMobileLink = (entry: menuItemContent) => {
    if ("url" in entry) {
      return (
        <ListItem key={entry.url} disablePadding>
          <ListItemButton>
            <ListItemText>
              <Link
                activeClassName="active"
                href={entry.url}
                onClick={() => setMenuOpen(false)}
              >
                {entry.label}
              </Link>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      );
    }
    if (entry.subMenu) {
      return (
        <React.Fragment>
          <ListSubheader>{entry.label}</ListSubheader>
          {entry.subMenu.map((subEntry) => (
            <ListItem
              key={subEntry.label}
              disablePadding
              style={{ marginLeft: "1em" }}
            >
              <ListItemButton>
                <ListItemText>{renderMobileSubLink(subEntry)}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  };

  return (
    <header class={style.header}>
      <div class="mobile" style="display:inline-block;">
        <h1>
          <Button onClick={() => setMenuOpen(true)} title="Open Menu">
            <MenuOutlinedIcon
              fontSize={"inherit"}
              style={{ fontSize: "45px", color: "#fff", marginTop: "-6px" }}
            />
          </Button>
          <span class="subMobile">{shortHeading || heading}</span>
          <span class="nonSubMobile">{heading}</span>
        </h1>

        <Drawer
          anchor="left"
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onKeyDown={() => setMenuOpen(false)}
          >
            <List>
              {links.map((link) => renderMobileLink(link))}
              {showAuth && (
                <ListItem key="auth" disablePadding>
                  <ListItemButton>
                    <ListItemText>
                      <Auth
                        currentUser={currentUser}
                        onClick={() => setMenuOpen(false)}
                        asMobile={true}
                      />
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
            </List>
            {/* <Divider /> */}
          </Box>
        </Drawer>
      </div>
      <h1 class="nonmobile">{heading}</h1>
      <nav class="nonmobile">
        {links.map((link) => renderNonmobileLink(link))}
        {showAuth && <Auth currentUser={currentUser} />}
      </nav>
    </header>
  );
};
export default Header;
