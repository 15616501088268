import { orderBy } from "firebase/firestore";
import { Location, PartialRecord } from "src/types";
import FormPage, { TableFormType } from "../../../../components/forms/table";

export default function AdminTable() {
  type TableType = Location;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Active", "active"],
    ["Description", "description"],
    ["Address", "address"],
    ["Map URL", "map_link"],
    // ["Venue Photos (one link per line)", "photos"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    active: "boolean",
    description: "textarea",
    address: "textarea",
    // photos: "photos",
  };

  const canDelete = false;
  const requiredFields = ["name"];

  const dbCollection = "locations";
  const displayOrder = [orderBy("active", "desc")];

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    displayOrder,
    canDelete,
  });
}
