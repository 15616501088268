import FormPage, { FormTab } from "../../../../components/forms/table";
import { AdminAttendance, AdminAttendanceSales } from "./attendance_base";
import { AdminClassesDefinition } from "./classes";

export default function AdminClassSalesAttendance() {
  const tabs: FormTab = {
    Summary: null,
    Sales: AdminAttendanceSales(),
    Attendance: AdminAttendance(),
  };

  const pageDefinition = {
    ...AdminClassesDefinition(),
    canDelete: false,
    list: true,
    tabs,
  };

  return FormPage(pageDefinition);
}
