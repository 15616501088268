import { PartialRecord, PersonCategory } from "src/types";
import FormPage, { TableFormType } from "../../../../components/forms/table";

export default function AdminCategories() {
  type TableType = PersonCategory;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Description", "description"],
    ["Admin only", "restricted"],
    ["Concession Discounts", "concessionRate"],
    ["Free entry (staff, children)", "freeEntry"],
    ["Non-Dancer (spectator, child, etc)", "nonParticipant"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    restricted: "boolean",
    concessionRate: "boolean",
    freeEntry: "boolean",
    nonParticipant: "boolean",
  };

  const canDelete = false;
  const requiredFields = ["name"];

  const dbCollection = "categories";

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    canDelete,
  });
}
