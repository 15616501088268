const template = `<!DOCTYPE html>
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <!-- NAME: LEFT SIDEBAR -->
    <!--[if gte mso 15]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>*|MC:SUBJECT|*</title>

    <style type="text/css">
      p {
        margin: 10px 0;
        padding: 0;
      }
      table {
        border-collapse: collapse;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 0;
        padding: 0;
      }
      img,
      a img {
        border: 0;
        height: auto;
        outline: none;
        text-decoration: none;
      }
      body,
      #bodyTable,
      #bodyCell {
        height: 100%;
        margin: 0;
        padding: 0;
        width: 100%;
      }
      .mcnPreviewText {
        display: none !important;
      }
      img {
        -ms-interpolation-mode: bicubic;
      }
      table {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
      p,
      a,
      li,
      td,
      blockquote {
        mso-line-height-rule: exactly;
      }
      a[href^="tel"],
      a[href^="sms"] {
        color: inherit;
        cursor: default;
        text-decoration: none;
      }
      p,
      a,
      li,
      td,
      body,
      table,
      blockquote {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
      #bodyCell {
        padding: 10px;
      }
      .templateContainer {
        max-width: 600px !important;
      }
      .mcnImage,
      .mcnRetinaImage {
        vertical-align: bottom;
      }
      .mcnTextContent {
        word-break: break-word;
      }
      .mcnTextContent img {
        height: auto !important;
      }
      .mcnDividerBlock {
        table-layout: fixed !important;
      }
      body,
      #bodyTable {
        background-color: #fafafa;
      }
      #bodyCell {
        border-top: 0;
      }
      .templateContainer {
        border: 0;
      }
      h1 {
        color: #202020;
        font-family: Helvetica;
        font-size: 26px;
        font-style: normal;
        font-weight: bold;
        line-height: 125%;
        letter-spacing: normal;
        text-align: left;
      }
      h2 {
        color: #202020;
        font-family: Helvetica;
        font-size: 22px;
        font-style: normal;
        font-weight: bold;
        line-height: 125%;
        letter-spacing: normal;
        text-align: left;
      }
      h3 {
        color: #202020;
        font-family: Helvetica;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: 125%;
        letter-spacing: normal;
        text-align: left;
      }
      h4 {
        color: #202020;
        font-family: Helvetica;
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        line-height: 125%;
        letter-spacing: normal;
        text-align: left;
      }
      #templateHeader {
        background-color: #ffffff;
        background-image: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-top: 0;
        border-bottom: 0;
        padding-top: 0px;
        padding-bottom: 0;
      }
      #templateHeader .mcnTextContent,
      #templateHeader .mcnTextContent p {
        color: #202020;
        font-family: Helvetica;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
      }
      #templateHeader .mcnTextContent a,
      #templateHeader .mcnTextContent p a {
        color: #2baadf;
        font-weight: normal;
        text-decoration: underline;
      }
      #templateSidebar {
        background-color: #ffffff;
        border-top: 0;
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      #templateSidebar .mcnTextContent,
      #templateSidebar .mcnTextContent p {
        color: #656565;
        font-family: Helvetica;
        font-size: 10px;
        line-height: 150%;
        text-align: left;
      }
      #templateSidebar .mcnTextContent a,
      #templateSidebar .mcnTextContent p a {
        color: #2baadf;
        font-weight: normal;
        text-decoration: underline;
      }
      #templateBody,
      #templateColumns {
        background-color: #ffffff;
        border-top: 0;
        border-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
      #templateBody .mcnTextContent,
      #templateBody .mcnTextContent p {
        color: #202020;
        font-family: Helvetica;
        font-size: 14px;
        line-height: 150%;
        text-align: left;
      }
      #templateBody .mcnTextContent a,
      #templateBody .mcnTextContent p a {
        color: #2baadf;
        font-weight: normal;
        text-decoration: underline;
      }
      #templateFooter {
        background-color: #fafafa;
        background-image: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-top: 0;
        border-bottom: 0;
        padding-top: 0px;
        padding-bottom: 9px;
      }
      #templateFooter .mcnTextContent,
      #templateFooter .mcnTextContent p {
        color: #656565;
        font-family: Helvetica;
        font-size: 10px;
        line-height: 150%;
        text-align: center;
      }
      #templateFooter .mcnTextContent a,
      #templateFooter .mcnTextContent p a {
        color: #656565;
        font-weight: normal;
        text-decoration: underline;
      }
      @media only screen and (min-width: 768px) {
        .templateContainer {
          width: 600px !important;
        }
      }
      @media only screen and (max-width: 480px) {
        body,
        table,
        td,
        p,
        a,
        li,
        blockquote {
          -webkit-text-size-adjust: none !important;
        }
        body {
          width: 100% !important;
          min-width: 100% !important;
        }
        #templateSidebar,
        #templateBody {
          max-width: 100% !important;
          width: 100% !important;
        }
        .mcnRetinaImage {
          max-width: 100% !important;
        }
        .mcnImage {
          width: 100% !important;
        }
        .mcnCaptionBottomContent,
        .mcnTextContentContainer {
          max-width: 100% !important;
          width: 100% !important;
        }
        .mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent {
          padding-top: 18px !important;
        }
        .mcnTextContent {
          padding-right: 18px !important;
          padding-left: 18px !important;
        }
        h1 {
          font-size: 22px !important;
          line-height: 125% !important;
        }
        h2 {
          font-size: 20px !important;
          line-height: 125% !important;
        }
        h3 {
          font-size: 18px !important;
          line-height: 125% !important;
        }
        h4 {
          font-size: 16px !important;
          line-height: 150% !important;
        }
        #templateHeader .mcnTextContent,
        #templateHeader .mcnTextContent p {
          font-size: 16px !important;
          line-height: 150% !important;
        }
        #templateSidebar .mcnTextContent,
        #templateSidebar .mcnTextContent p {
          font-size: 16px !important;
          line-height: 150% !important;
        }
        #templateBody .mcnTextContent,
        #templateBody .mcnTextContent p {
          font-size: 16px !important;
          line-height: 150% !important;
        }
        #templateFooter .mcnTextContent,
        #templateFooter .mcnTextContent p {
          font-size: 14px !important;
          line-height: 150% !important;
        }
      }
    </style>
    <script>
      !(function (a) {
        var e = "https://s.go-mpulse.net/boomerang/",
          t = "addEventListener";
        if ("True" == "True")
          (a.BOOMR_config = a.BOOMR_config || {}),
            (a.BOOMR_config.PageParams = a.BOOMR_config.PageParams || {}),
            (a.BOOMR_config.PageParams.pci = !0),
            (e = "https://s2.go-mpulse.net/boomerang/");
        if (
          ((window.BOOMR_API_key = "QAT5G-9HZLF-7EDMX-YMVCJ-QZJDA"),
          (function () {
            function n(e) {
              a.BOOMR_onload = (e && e.timeStamp) || new Date().getTime();
            }
            if (!a.BOOMR || (!a.BOOMR.version && !a.BOOMR.snippetExecuted)) {
              (a.BOOMR = a.BOOMR || {}), (a.BOOMR.snippetExecuted = !0);
              var i,
                _,
                o,
                r = document.createElement("iframe");
              if (a[t]) a[t]("load", n, !1);
              else if (a.attachEvent) a.attachEvent("onload", n);
              (r.src = "javascript:void(0)"),
                (r.title = ""),
                (r.role = "presentation"),
                ((r.frameElement || r).style.cssText =
                  "width:0;height:0;border:0;display:none;"),
                (o = document.getElementsByTagName("script")[0]),
                o.parentNode.insertBefore(r, o);
              try {
                _ = r.contentWindow.document;
              } catch (O) {
                (i = document.domain),
                  (r.src =
                    "javascript:var d=document.open();d.domain='" +
                    i +
                    "';void(0);"),
                  (_ = r.contentWindow.document);
              }
              (_.open()._l = function () {
                var a = this.createElement("script");
                if (i) this.domain = i;
                (a.id = "boomr-if-as"),
                  (a.src = e + "QAT5G-9HZLF-7EDMX-YMVCJ-QZJDA"),
                  (BOOMR_lstart = new Date().getTime()),
                  this.body.appendChild(a);
              }),
                _.write("<bo" + 'dy onload="document._l();">'),
                _.close();
            }
          })(),
          "400".length > 0)
        )
          if (
            a &&
            "performance" in a &&
            a.performance &&
            "function" == typeof a.performance.setResourceTimingBufferSize
          )
            a.performance.setResourceTimingBufferSize(400);
        !(function () {
          if (
            ((BOOMR = a.BOOMR || {}),
            (BOOMR.plugins = BOOMR.plugins || {}),
            !BOOMR.plugins.AK)
          ) {
            var e = "" == "true" ? 1 : 0,
              t = "",
              n = "hntgvolikxxouznz7ura-f-9940db3d6-clientnsv4-s.akamaihd.net",
              i = "false" == "true" ? 2 : 1,
              _ = {
                "ak.v": "36",
                "ak.cp": "405185",
                "ak.ai": parseInt("307432", 10),
                "ak.ol": "0",
                "ak.cr": 23,
                "ak.ipv": 4,
                "ak.proto": "h2",
                "ak.rid": "4bd5d9f",
                "ak.r": 23319,
                "ak.a2": e,
                "ak.m": "x",
                "ak.n": "essl",
                "ak.bpcip": "59.102.106.0",
                "ak.cport": 58518,
                "ak.gh": "23.220.77.47",
                "ak.quicv": "",
                "ak.tlsv": "tls1.3",
                "ak.0rtt": "",
                "ak.csrc": "-",
                "ak.acc": "",
                "ak.t": "1706687778",
                "ak.ak":
                  "hOBiQwZUYzCg5VSAfCLimQ==WeyCeFRsrW1k0zygqfmtMibJ2tF9oMu88mF47D1N+FMj/yvM9rU0aMQUfoYO4bKZWxdSYArHRpmu9qXwlG5sl0jj555wxc+5z2lLDPvMyDjfYAWLcxIIn+1uM12Mq7RKGKa0TkLuLM74dy00MEnDNpUsxKz2aUcd0jrCGuDhxfeMADFl3Ey9RkEtjginF0Oll5L9u0OX0a3kgE7/Zm60Ll199dfaCXotHnDCfIalpVcVXDl3mBuAae/tjQ9RTuFQeHoNyCQE0st+LW9Nr5uzsAj2iW9NImQBSx+cLnAIZrfxptgQhcXxSSkLSD3Hb+LqU96njxBJg73IOV3L8MGBxXyg7UdAlDUUhwHuyYHmeKlkpn6lgp4cJQGuFjNl3YshhTdD9FSm2S6If22pG4SrU0JhTphA31Coyz+LQ1h3uI0=",
                "ak.pv": "60",
                "ak.dpoabenc": "",
                "ak.tf": i,
              };
            if ("" !== t) _["ak.ruds"] = t;
            var o = {
              i: !1,
              av: function (e) {
                var t = "http.initiator";
                if (e && (!e[t] || "spa_hard" === e[t]))
                  (_["ak.feo"] = void 0 !== a.aFeoApplied ? 1 : 0),
                    BOOMR.addVar(_);
              },
              rv: function () {
                var a = [
                  "ak.bpcip",
                  "ak.cport",
                  "ak.cr",
                  "ak.csrc",
                  "ak.gh",
                  "ak.ipv",
                  "ak.m",
                  "ak.n",
                  "ak.ol",
                  "ak.proto",
                  "ak.quicv",
                  "ak.tlsv",
                  "ak.0rtt",
                  "ak.r",
                  "ak.acc",
                  "ak.t",
                  "ak.tf",
                ];
                BOOMR.removeVar(a);
              },
            };
            BOOMR.plugins.AK = {
              akVars: _,
              akDNSPreFetchDomain: n,
              init: function () {
                if (!o.i) {
                  var a = BOOMR.subscribe;
                  a("before_beacon", o.av, null, null),
                    a("onbeacon", o.rv, null, null),
                    (o.i = !0);
                }
                return this;
              },
              is_complete: function () {
                return !0;
              },
            };
          }
        })();
      })(window);
    </script>
  </head>
  <body>
    <!--*|IF:MC_PREVIEW_TEXT|*-->
    <!--[if !gte mso 9]><!----><span
      class="mcnPreviewText"
      style="
        display: none;
        font-size: 0px;
        line-height: 0px;
        max-height: 0px;
        max-width: 0px;
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
        mso-hide: all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
    <!--*|END:IF|*-->
    <center>
      <table
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        height="100%"
        width="100%"
        id="bodyTable">
        <tr>
          <td align="center" valign="top" id="bodyCell">
            <!-- BEGIN TEMPLATE // -->
            <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                        <tr>
                        <td align="center" valign="top" width="600" style="width:600px;">
                        <![endif]-->
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              width="100%"
              class="templateContainer">
              <tr>
                <td valign="top" id="templateHeader">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    class="mcnDividerBlock"
                    style="min-width: 100%; background-color: #f68712">
                    <tbody class="mcnDividerBlockOuter">
                      <tr>
                        <td
                          class="mcnDividerBlockInner"
                          style="min-width: 100%; padding: 5px 18px">
                          <span></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    class="mcnImageBlock"
                    style="min-width: 100%">
                    <tbody class="mcnImageBlockOuter">
                      <tr>
                        <td
                          valign="top"
                          style="padding: 0px"
                          class="mcnImageBlockInner">
                          <table
                            align="left"
                            width="100%"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="mcnImageContentContainer"
                            style="min-width: 100%">
                            <tbody>
                              <tr>
                                <td
                                  class="mcnImageContent"
                                  valign="top"
                                  style="
                                    padding-right: 0px;
                                    padding-left: 0px;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    text-align: center;">
                                  <img
                                    align="center"
                                    alt=""
                                    src="https://gallery.mailchimp.com/1e91ff7a96f59f0bcf06cab7f/images/249b0948-886f-4ddb-a461-dfe5128007e4.png"
                                    width="600"
                                    style="
                                      max-width: 825px;
                                      padding-bottom: 0;
                                      display: inline !important;
                                      vertical-align: bottom;
                                    "
                                    class="mcnImage"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <table
                    align="center"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    id="templateColumns">
                    <tr>
                      <td valign="top">
                        <!--[if (gte mso 9)|(IE)]>
                                                <table align="center" border="0" cellspacing="0" cellpadding="0" dir="rtl" width="600" style="width:600px;">
                                                <tr>
                                                <td align="center" dir="ltr" valign="top" width="400" style="width:400px;">
                                                <![endif]-->
                        <table
                          align="right"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="400"
                          id="templateBody">
                          <tr>
                            <td>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="400" style="width:400px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding: 0px 18px 9px;
                                                font-size: 12px;">
                                              *|IF:FNAME|*Hi *|FNAME|*.<br />
                                              *|END:IF|*Here's the latest news
                                              from *|USER:COMPANY|*!<br />
                                              *|IFNOT:ARCHIVE_PAGE|**|IF:REMINDER|*<br />
                                              *|REMINDER|*<br />
                                              *|END:IF|**|IF:PASSALERT>0|*It
                                              looks like your current pass may
                                              *|IF:PASSALERT>1|*have
                                              expired*|ELSE:|*be about to
                                              expire*|END:IF|* - see below!<br />
                                              *|END:IF|**|END:IF|*<br />
                                              <a
                                                href="*|ARCHIVE|*"
                                                target="_blank">View this email in your
                                                browser</a><br />
                                              You can also <a
                                                href="*|UNSUB|*">unsubscribe</a> (although we'll miss
                                              dancing with you!)

                                              <hr />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="400" style="width:400px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding-top: 0;
                                                padding-right: 18px;
                                                padding-bottom: 9px;
                                                padding-left: 18px;">
                                              <templatebody />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="400" style="width:400px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding: 0px 18px 9px;
                                                font-size: 12px;">
                                              *|IFNOT:ARCHIVE_PAGE|**|IF:STAFF|*You're recorded
                                              as a member of staff.<br />
                                              Thanks! Keep up the good work!
                                              :)*|ELSEIF:PASSNOTES|*<span
                                                style="font-size: 18px"><strong><span style="color: #8c2b99">Your Passes</span></strong></span><br />
                                              *|PASSNOTES|*<br />
                                              *|IF:LASTUPDATE|*Information about
                                              your passes was last updated
                                              *|LASTUPDATE|*.*|END:IF|* If you
                                              have placed a bank transfer to
                                              purchase a pass, it will be
                                              activated at your next class
                                              night.<br />
                                              If you would like to purchase via
                                              EFT, or believe this pass
                                              information to be incorrect,
                                              please speak to our staff at
                                              class.<br />
                                              Over our Christmas break, or if we
                                              have to cancel a class, all active
                                              passes will be extended.
                                              <hr />
                                              *|END:IF|**|END:IF|*
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="400" style="width:400px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding: 0px 18px 9px;
                                                font-size: 12px;">
                                              See you on the dance-floor!<br />
                                              *|USER:COMPANY|*
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                                                </td>
                                                <td align="center" dir="ltr" valign="top" width="200" style="width:200px;">
                                                <![endif]-->
                        <table
                          align="left"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="200"
                          id="templateSidebar">
                          <tr>
                            <td valign="top">
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnCaptionBlock">
                                <tbody class="mcnCaptionBlockOuter">
                                  <tr>
                                    <td
                                      class="mcnCaptionBlockInner"
                                      valign="top"
                                      style="padding: 9px">
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="mcnCaptionBottomContent"
                                        width="false">
                                        <tbody>
                                          <tr>
                                            <td
                                              class="mcnCaptionBottomImageContent"
                                              align="center"
                                              valign="top"
                                              style="padding: 0 9px 9px 9px">
                                              <img
                                                alt=""
                                                src="https://gallery.mailchimp.com/1e91ff7a96f59f0bcf06cab7f/images/bb6bc7a5-549b-448e-afae-b406b5f9dbf7.jpeg"
                                                width="95"
                                                style="max-width: 95px"
                                                class="mcnImage"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="mcnTextContent"
                                              valign="top"
                                              style="padding: 0 9px 0 9px"
                                              width="164"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnCaptionBlock">
                                <tbody class="mcnCaptionBlockOuter">
                                  <tr>
                                    <td
                                      class="mcnCaptionBlockInner"
                                      valign="top"
                                      style="padding: 9px">
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        class="mcnCaptionBottomContent"
                                        width="false">
                                        <tbody>
                                          <tr>
                                            <td
                                              class="mcnCaptionBottomImageContent"
                                              align="center"
                                              valign="top"
                                              style="padding: 0 9px 9px 9px">
                                              <img
                                                alt=""
                                                src="https://gallery.mailchimp.com/1e91ff7a96f59f0bcf06cab7f/images/37097a59-ca7f-4349-b22f-faf902f0bfe4.png"
                                                width="164"
                                                style="max-width: 207px"
                                                class="mcnImage"
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              class="mcnTextContent"
                                              valign="top"
                                              style="padding: 0 9px 0 9px"
                                              width="164"></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnDividerBlock"
                                style="
                                  min-width: 100%;
                                  background-color: #8c2b99;">
                                <tbody class="mcnDividerBlockOuter">
                                  <tr>
                                    <td
                                      class="mcnDividerBlockInner"
                                      style="min-width: 100%; padding: 9px 18px">
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnDividerBlock"
                                style="
                                  min-width: 100%;
                                  background-color: #8c2b99;">
                                <tbody class="mcnDividerBlockOuter">
                                  <tr>
                                    <td
                                      class="mcnDividerBlockInner"
                                      style="min-width: 100%; padding: 9px 18px">
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="200" style="width:200px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding-top: 0;
                                                padding-right: 18px;
                                                padding-bottom: 9px;
                                                padding-left: 18px;">
                                              <strong>Thursday 7pm-10pm</strong>

                                              <div>The Irish Club Hall</div>

                                              <div>
                                                11 Carrington Street, Adelaide
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnDividerBlock"
                                style="
                                  min-width: 100%;
                                  background-color: #8c2b99;">
                                <tbody class="mcnDividerBlockOuter">
                                  <tr>
                                    <td
                                      class="mcnDividerBlockInner"
                                      style="min-width: 100%; padding: 9px 18px">
                                      <span></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                width="100%"
                                class="mcnTextBlock"
                                style="min-width: 100%">
                                <tbody class="mcnTextBlockOuter">
                                  <tr>
                                    <td
                                      valign="top"
                                      class="mcnTextBlockInner"
                                      style="padding-top: 9px">
                                      <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                                      <!--[if mso]>
				<td valign="top" width="200" style="width:200px;">
				<![endif]-->
                                      <table
                                        align="left"
                                        border="0"
                                        cellpadding="0"
                                        cellspacing="0"
                                        style="max-width: 100%; min-width: 100%"
                                        width="100%"
                                        class="mcnTextContentContainer">
                                        <tbody>
                                          <tr>
                                            <td
                                              valign="top"
                                              class="mcnTextContent"
                                              style="
                                                padding-top: 0;
                                                padding-right: 18px;
                                                padding-bottom: 9px;
                                                padding-left: 18px;">
                                              Web:
                                              <a
                                                href="http://www.ceroc-adelaide.com.au"
                                                target="_blank">www.ceroc-adelaide.com.au</a><br />
                                              Email:
                                              <a
                                                href="mailto:*|LIST:EMAIL|*"
                                                target="_blank">*|LIST:EMAIL|*</a><br />
                                              Facebook:
                                              <a
                                                href="https://www.facebook.com/ceroc"
                                                target="_blank">facebook.com/ceroc</a><br />
                                              IG:
                                              <a
                                                href="https://www.instagram.com/ceroc_modernjive_adelaide/"
                                                target="_blank">ceroc_modernjive_adelaide</a><br />
                                              Phone:
                                              <a
                                                href="tel:+*|LIST:PHONE|*"
                                                target="_blank">*|LIST:PHONE|*</a><br />
                                              <span
                                                style="font-size: Default Size">Meetup:
                                                <a
                                                  href="https://www.meetup.com/Ceroc-Modern-Jive-Adelaide/"
                                                  target="_blank">Ceroc Adelaide</a></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <!--[if mso]>
				</td>
				<![endif]-->

                                      <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if (gte mso 9)|(IE)]>
                                                </td>
                                                </tr>
                                                </table>
                                                <![endif]-->
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td valign="top" id="templateFooter">
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    class="mcnImageBlock"
                    style="min-width: 100%">
                    <tbody class="mcnImageBlockOuter">
                      <tr>
                        <td
                          valign="top"
                          style="padding: 0px"
                          class="mcnImageBlockInner">
                          <table
                            align="left"
                            width="100%"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="mcnImageContentContainer"
                            style="min-width: 100%">
                            <tbody>
                              <tr>
                                <td
                                  class="mcnImageContent"
                                  valign="top"
                                  style="
                                    padding-right: 0px;
                                    padding-left: 0px;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    text-align: center;">
                                  <img
                                    align="center"
                                    alt=""
                                    src="https://gallery.mailchimp.com/1e91ff7a96f59f0bcf06cab7f/images/12843829-d6fc-42fb-ac65-a7cfec8d0118.png"
                                    width="600"
                                    style="
                                      max-width: 775px;
                                      padding-bottom: 0;
                                      display: inline !important;
                                      vertical-align: bottom;
                                    "
                                    class="mcnImage"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    class="mcnDividerBlock"
                    style="min-width: 100%; background-color: #8c2b99">
                    <tbody class="mcnDividerBlockOuter">
                      <tr>
                        <td
                          class="mcnDividerBlockInner"
                          style="min-width: 100%; padding: 5px 18px">
                          <span></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    width="100%"
                    class="mcnTextBlock"
                    style="min-width: 100%">
                    <tbody class="mcnTextBlockOuter">
                      <tr>
                        <td
                          valign="top"
                          class="mcnTextBlockInner"
                          style="padding-top: 9px">
                          <!--[if mso]>
				<table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
				<tr>
				<![endif]-->

                          <!--[if mso]>
				<td valign="top" width="600" style="width:600px;">
				<![endif]-->
                          <table
                            align="left"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            style="max-width: 100%; min-width: 100%"
                            width="100%"
                            class="mcnTextContentContainer">
                            <tbody>
                              <tr>
                                <td
                                  valign="top"
                                  class="mcnTextContent"
                                  style="
                                    padding-top: 0;
                                    padding-right: 18px;
                                    padding-bottom: 9px;
                                    padding-left: 18px;">
                                  <em>Copyright © *|CURRENT_YEAR|*
                                    *|LIST:COMPANY|*, All rights reserved.</em><br />
                                  *|IFNOT:ARCHIVE_PAGE|* *|LIST:DESCRIPTION|*<br />
                                  <br />
                                  <strong>Our mailing address is:</strong><br />
                                  <span style="font-size: Default Size">*|HTML:LIST_ADDRESS_HTML|*</span>
                                  *|END:IF|*<br />
                                  This email was sent to*|IF:FNAME|* *|FNAME|*
                                  at *|END:IF|* *|EMAIL|*.<br />
                                  <a href="*|ARCHIVE|*" target="_blank">View this email in your browser</a><br />
                                  You can also <a href="*|UNSUB|*">unsubscribe</a> (although we'll miss dancing with
                                  you!)<br />
                                  <br />
                                  *|REWARDS|*
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!--[if mso]>
				</td>
				<![endif]-->

                          <!--[if mso]>
				</tr>
				</table>
				<![endif]-->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <!--[if (gte mso 9)|(IE)]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->
            <!-- // END TEMPLATE -->
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>`;

export const renderNewsletter = (body: string) => {
  return template.replace("<templatebody />", body);
};
