import {
  DocumentReference,
  Timestamp,
  and,
  collectionGroup,
  getDoc,
  getDocs,
  or,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useState } from "preact/hooks";
import { db } from "../../../../firebase";
import { Pass, Person } from "../../../../types";

interface PassWithPerson extends Pass {
  person: Person;
}

export default function AdminActivePasses() {
  const [activePasses, setActivePasses] = useState<PassWithPerson[]>();
  const now = new Date();
  const warnFrom = new Date();
  warnFrom.setDate(warnFrom.getDate() + 14);

  if (!activePasses) {
    const since = new Date();
    since.setDate(since.getDate() - 30);
    getDocs(
      query(
        collectionGroup(db, "passes"),
        and(
          where("has_remaining_uses", "==", true),
          or(
            where("expires", ">", Timestamp.fromDate(since)),
            where("expires", "==", Timestamp.fromMillis(0))
          )
        ),
        orderBy("expires", "desc"),
        orderBy("remaining_uses", "desc")
      )
    ).then((snapshot) => {
      Promise.all(
        snapshot.docs.map((doc) =>
          getDoc(doc.ref.parent.parent as DocumentReference)
        )
      ).then((peopleRefs) => {
        setActivePasses(
          snapshot.docs.map((doc, i) => {
            return {
              ...doc.data(),
              person: peopleRefs[i].data(),
            } as PassWithPerson;
          })
        );
      });
    });

    return (
      <div class="app-body-wrap">
        <div class="app-body">Loading...</div>
      </div>
    );
  }

  const renderPass = (pass: PassWithPerson) => {
    const passExpiry = pass.expires?.toDate();
    const expiryClass =
      passExpiry < now ? "alert" : passExpiry < warnFrom ? "warn" : "";
    return (
      <tr class={expiryClass}>
        <td>{pass.person.name}</td>
        <td>{pass.name}</td>
        <td>{passExpiry?.toLocaleDateString()}</td>
        <td>{pass.remaining_uses}</td>
        {/* Options */}
      </tr>
    );
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Active Passes</h2>
        <table>
          <tr>
            <th>Name</th>
            <th>Pass Type</th>
            <th>Expires/Term</th>
            <th>Remaining</th>
            {/* Options */}
          </tr>
          {activePasses.map(renderPass)}
        </table>
      </div>
    </div>
  );
}
