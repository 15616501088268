import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "preact/hooks";
import { setUrlParam } from "../../utils/utils";

interface TabSetProps {
  defaultTab?: number;
  tabs: Record<string, JSX.Element>;
}

export const TabSet = (props: TabSetProps) => {
  const tabIdFromUrl = new URLSearchParams(location.search).get("tabId");
  const initialTabId = tabIdFromUrl ? parseInt(tabIdFromUrl) : undefined;

  const [tabIndex, _setTabIndex] = useState<number>(
    initialTabId ?? props.defaultTab ?? 0
  );

  const setTabIndex = (index: number) => {
    _setTabIndex(index);
    setUrlParam("tabId", index.toString());
  };

  useEffect(() => {
    return () => setUrlParam("tabId");
  }, []);

  return (
    <>
      <Tabs
        value={tabIndex}
        allowScrollButtonsMobile={true}
        variant="scrollable"
        onChange={(_event, value: number) => setTabIndex(value)}
      >
        {Object.keys(props.tabs).map((tab) => (
          <Tab label={tab} />
        ))}
      </Tabs>
      {Object.values(props.tabs).map((tab, index) => {
        return <div hidden={index !== tabIndex}>{tab}</div>;
      })}
    </>
  );
};
