import {
  ComponentTypeNames,
  pageEntry,
  claimSetKeys,
  PartialRecord,
} from "../../../types";

import FormPage, { TableFormType } from "../../../components/forms/table";

export default function AdminPages() {
  const dbCollection = "html-published";

  type TableType = pageEntry;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Title", "title"],
    ["URL", "id"],
    ["Plugin", "component"],
    ["Menu Order", "menuOrder"],
    ["App Page", "topLevelApp"],
    ["Required Role", "role"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    topLevelApp: "boolean",
    component: ComponentTypeNames,
    role: claimSetKeys,
    id: "path",
  };

  const canDelete = true;
  const requiredFields = ["url", "component", "title"];

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    canDelete,
  });
}
