import { DocumentReference, Timestamp, doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { ExtractPassTypes, ImportPassTypes } from "./import/pass";
import { FirebaseBackedObject } from "src/types";
import { ExtractPeople, ImportPeople } from "./import/person";
import { ExtractTerms, ImportTerms } from "./import/terms";
import { ExtractStock, ImportStock } from "./import/stock";
import { ExtractSales, ImportSales } from "./import/sale";
import { ExtractDiscounts, ImportDiscounts } from "./import/discounts";
import { ExtractAttendance, ImportAttendance } from "./import/attendance";
import { ExtractEntry, ImportEntry } from "./import/entry";
import { TimestampMax } from "./types";

export type ImportTimes = Record<string, Timestamp>;

const SchemaCheck = (input: string, schema: string) => {
  if (input.indexOf(schema) < 0) {
    throw new Error(
      "Exact match of schema not found in input; aborting to be safe"
    );
  }
  return true;
};

// Wrong way to do it, but until we switch systems it shouldn't fail...
export const CategoryMap: Record<number, DocumentReference> = {
  0: doc(db, "/categories/XTe7dCbsBWUS9fcprIqh"),
  1: doc(db, "/categories/8a6R2JDSAIRtDu4r5P89"),
  2: doc(db, "/categories/DdvJ8EKZXTpJpTLSxYMh"),
  3: doc(db, "/categories/XTe7dCbsBWUS9fcprIqh"),
  4: doc(db, "/categories/SLRsiXa567szFg4n7TYW"),
  5: doc(db, "/categories/Bw4O8LbXLFapQHtmYaKs"),
  7: doc(db, "/categories/Bw4O8LbXLFapQHtmYaKs"),
  9: doc(db, "/categories/Dy9azL0gqCm0O2ntJ0yF"),
};

export const SourceMap: Record<number, DocumentReference> = {
  1: doc(db, "/sources/FtIFTnuED7yPPQBGSBvK"),
  2: doc(db, "/sources/vdJdqoutH9silEjuqqZg"),
  3: doc(db, "/sources/dfmbjrG8w8LRCurZ6x9c"),
  4: doc(db, "/sources/bxpeYuPA1zJmQAE3CAIj"),
  5: doc(db, "/sources/Nt9NuH5Sb6GwojN4Zp2D"),
  6: doc(db, "/sources/N3I1qiU8YNOxd0f1UwpI"),
  7: doc(db, "/sources/A2fBznXK2KEcDsV0h3EA"),
  8: doc(db, "/sources/Icic4VIwLeYD0OOfSTvQ"),
  9: doc(db, "/sources/AzHutlzzIs10SxGiPwAx"),
  10: doc(db, "/sources/4e4XnkoJW8O0xhtzjGSx"),
  11: doc(db, "/sources/ObfcRxLowykrlF2cSXOh"),
  12: doc(db, "/sources/VCtPXcYMmeaKiXpUkRR7"),
  13: doc(db, "/sources/lhW53yIzVq8aM8wFN6cV"),
  14: doc(db, "/sources/SJOKnFPGCNVsemFntWjs"),
  15: doc(db, "/sources/fyLP3rsil3RWd3xAAyq3"),
  16: doc(db, "/sources/b2kSEZEbXkPKPOcgQDsy"),
};

export const timeToTimestamp = (when?: number | Timestamp) => {
  if (typeof when === "object") {
    return when;
  }
  if (!when) {
    return TimestampMax;
  }
  return new Timestamp(when / 1000, 0);
};

const RE_TYPES = {
  int: "(\\d+|NULL)",
  float: "(\\d+(?:\\.\\d+)?|NULL)",
  str: "(\"[^\"]*\"|'[^']*(?:\\\\'[^']+)*'|NULL)",
  date: "('\\d{4}-\\d{2}-\\d{2}'|NULL)",
  YN: "'([YN]?)'",
};
export type RE_TYPE_NAME = "int" | "float" | "str" | "date" | "YN";

const unquote = (input: string) => {
  const quoteChar = input[0];
  return input.slice(1, input.length - 1).replace("\\" + quoteChar, quoteChar);
};

const parseVar = (type: RE_TYPE_NAME, val: string) => {
  if (val === "NULL") {
    return null;
  }
  if (type === "str") {
    return unquote(val);
  }
  if (type === "int") {
    return parseInt(val);
  }
  if (type === "float") {
    return parseFloat(val);
  }
  if (type === "date") {
    return Date.parse(unquote(val));
  }
  if (type === "YN") {
    if (!val) {
      return undefined;
    }
    return val === "Y";
  }
  return val;
};

const ExtractTuples = <T>(
  input: string,
  tableName: string,
  types: Record<string, RE_TYPE_NAME>
) => {
  const insertRegex = new RegExp(
    "INSERT INTO `" + tableName + "` VALUES ([^\n]+);"
  );
  const insertMatched = input.match(insertRegex);
  if (!insertMatched || !insertMatched[1]) {
    throw new Error("Didn't match INSERT line");
  }

  const RE_SCHEMA = new RegExp(
    "\\(" +
      Object.values(types)
        .map((type) => RE_TYPES[type])
        .join(",") +
      "\\)",
    "g"
  );
  const matches = insertMatched[1].matchAll(RE_SCHEMA);
  return Array.from(matches).map(
    (row) =>
      Object.fromEntries(
        Object.keys(types)
          .map((column, i) =>
            column.startsWith("_")
              ? []
              : [column, parseVar(types[column], row[i + 1])]
          )
          .filter((row) => row.length > 0)
      ) as T
  );
};

const SaveRecords = <T>(
  records: T[],
  collection: string,
  collectionResolver?: (_: T) => string
): Promise<number> => {
  const savePromises = records.map((record) => {
    const { id, ...data } = record as { id: number };
    const collectionName = collectionResolver
      ? collectionResolver(record)
      : collection;
    return setDoc(doc(db, collectionName, (id || "").toString()), data);
  });
  return new Promise((resolve, reject) =>
    Promise.all(savePromises)
      .then((values) => {
        setDoc(
          doc(db, "import/times"),
          { [collection]: new Timestamp(Date.now() / 1000, 0) },
          { merge: true }
        )
          .then(() => resolve(values.length))
          .catch(reject);
      })
      .catch(reject)
  );
};

export type DB_TABLE_NAME =
  | "Pass"
  | "Person"
  | "Terms"
  | "Stock"
  | "Sale"
  | "Discount"
  | "Entry"
  | "Attendance";

export type CollectionCache =
  | Record<string, Record<string, DocumentReference>>
  | undefined;

const extractors: Record<
  DB_TABLE_NAME,
  (_: string, _a: ImportTimes, _b?: CollectionCache) => FirebaseBackedObject[]
> = {
  Pass: ExtractPassTypes,
  Person: ExtractPeople,
  Terms: ExtractTerms,
  Stock: ExtractStock,
  Sale: ExtractSales,
  Discount: ExtractDiscounts,
  Attendance: ExtractAttendance,
  Entry: ExtractEntry,
};

const importers: Record<
  DB_TABLE_NAME,
  (_: string, _a: ImportTimes, _b?: CollectionCache) => Promise<number>
> = {
  Pass: ImportPassTypes,
  Person: ImportPeople,
  Terms: ImportTerms,
  Stock: ImportStock,
  Sale: ImportSales,
  Discount: ImportDiscounts,
  Attendance: ImportAttendance,
  Entry: ImportEntry,
};

const Extract = (
  type: DB_TABLE_NAME,
  input: string,
  lastImports: ImportTimes,
  tableData?: CollectionCache
) => {
  return extractors[type](input, lastImports, tableData);
};

const Import = (
  type: DB_TABLE_NAME,
  input: string,
  lastImports: ImportTimes,
  tableData?: CollectionCache
) => {
  return importers[type](input, lastImports, tableData);
};

export { ExtractTuples, SchemaCheck, SaveRecords, Extract, Import };
