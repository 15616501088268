import { Timestamp } from "firebase/firestore";

const zeroPad2 = (num: number, len = 2) => {
  const asStr = num.toString();
  const padCount = len - asStr.length;
  if (padCount <= 0) {
    return num.toString();
  }
  return "0".repeat(padCount) + num.toString();
};

export const dateFormat = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return;
  }

  const date = timestamp.toDate();
  const dateStr = `${date.getFullYear()}-${zeroPad2(
    date.getMonth() + 1
  )}-${zeroPad2(date.getDate())}`;
  return dateStr;
};

export const dateFormatAsLocal = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return;
  }

  const date = timestamp.toDate();
  const dateStr = `${date.getUTCFullYear()}-${zeroPad2(
    date.getUTCMonth() + 1
  )}-${zeroPad2(date.getUTCDate())}`;
  return dateStr;
};

export const dateTimeLocalFormat = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return;
  }
  const date = timestamp.toDate();
  const dateStr = `${date.getFullYear()}-${zeroPad2(
    date.getMonth() + 1
  )}-${zeroPad2(date.getDate())}`;
  const timeStr = `${zeroPad2(date.getHours())}:${zeroPad2(date.getMinutes())}`;
  return dateStr + "T" + timeStr;
};

export const dateFormatAU = (timestamp?: Timestamp | Date) => {
  if (!timestamp) {
    return;
  }

  const date = "toDate" in timestamp ? timestamp.toDate() : timestamp;
  const dateStr = `${zeroPad2(date.getDate())}/${zeroPad2(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
  return dateStr;
};

export const dateRangeFormat = (
  startTimestamp: Timestamp,
  endTimestamp?: Timestamp
) => {
  const start = startTimestamp.toDate();
  const end = endTimestamp?.toDate();
  const laterYear = start.getFullYear() !== new Date().getFullYear();

  const includeTime = start.getHours() > 0;
  const formatOptionsBase = {
    year: laterYear ? "numeric" : undefined,
    month: "long",
    day: "numeric",
    hour: includeTime ? "numeric" : undefined,
    minute: includeTime ? "numeric" : undefined,
  } as const;
  if (!end) {
    return start.toLocaleDateString("en-AU", formatOptionsBase);
  }

  return (
    start.toLocaleDateString("en-AU", formatOptionsBase) +
    " - " +
    end.toLocaleDateString("en-AU", formatOptionsBase)
  );
};

export const weeksAway = (millis: number) => {
  const weeks = millis / 1000 / 60 / 60 / 24 / 7;
  if (weeks < 1) {
    return "this week";
  }
  if (weeks < 2) {
    return "next week";
  }
  return "in " + Math.floor(weeks) + " weeks";
};

export const sameDate = (a: Date, b: Date) => {
  if (a.getDate() !== b.getDate()) {
    return false;
  }
  if (a.getMonth() !== b.getMonth()) {
    return false;
  }
  if (a.getFullYear() !== b.getFullYear()) {
    return false;
  }
  return true;
};

export const timeFormat = (when: Date, includeDate: boolean) => {
  const formatOptionsBase = {
    hour: "numeric",
    minute: "numeric",
    day: includeDate ? "numeric" : undefined,
    month: includeDate ? "numeric" : undefined,
    year: includeDate ? "numeric" : undefined,
  } as const;
  return when.toLocaleTimeString("en-AU", formatOptionsBase);
};
