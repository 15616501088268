import {
  getDocs,
  query,
  collection,
  where,
  orderBy,
  limit,
  or,
  Timestamp,
} from "firebase/firestore";
import { DOOR_PREFIX, db } from "../../firebase";
import { Pass } from "../../types";

export const getPersonActivePassesQuery = (personId: string) =>
  query(
    collection(db, DOOR_PREFIX + `person/${personId}/passes/`),
    where("expires", ">=", new Date()),
    where("has_remaining_uses", "==", true),
    orderBy("expires", "desc"),
    orderBy("remaining_uses", "desc"),
    limit(50)
  );

export const getPersonPassesQuery = (personId: string) =>
  query(
    collection(db, DOOR_PREFIX + `person/${personId}/passes/`),
    orderBy("expires", "desc"),
    orderBy("remaining_uses", "desc"),
    limit(20)
  );

export const getPersonPasses = (
  personId: string,
  currentOnly = false
): Promise<Pass[]> => {
  return new Promise((resolve) => {
    const name = DOOR_PREFIX + `person/${personId}/passes/`;
    // 2-stage; all active, then up to 50 inactive
    getDocs(getPersonActivePassesQuery(personId)).then((snapshot) => {
      const activePassRefs = snapshot.docs.map((doc) => {
        return { id: doc.id, collectionPath: doc.ref.path, ...doc.data() };
      }) as Pass[];

      if (currentOnly) {
        resolve(activePassRefs);
        return;
      }

      const activePasses = activePassRefs.map((ref) => ref.id);

      getDocs(
        query(
          collection(db, name),
          or(
            where("expires", ">", Timestamp.fromMillis(0)),
            where("multiuse", "==", true),
            where("pass_is_multiuse", "==", true)
          ),
          orderBy("expires", "desc"),
          orderBy("remaining_uses", "desc"),
          limit(50)
        )
      ).then((snapshot) => {
        const inactivePassRefs = snapshot.docs.map((doc) => {
          return { id: doc.id, collectionPath: doc.ref.path, ...doc.data() };
        }) as Pass[];
        resolve([
          ...activePassRefs,
          ...inactivePassRefs.filter((pass) => !activePasses.includes(pass.id)),
        ]);
      });
    });
  });
};
