import { FirebaseValueType, PartialRecord, PassType } from "src/types";
import FormPage, { TableFormType } from "../../../../components/forms/table";
import { orderBy } from "firebase/firestore";

export default function AdminPassTypes() {
  type TableType = PassType;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Code", "code"],
    ["Price", "price"],
    ["No. Classes", "valid_classes"],
    ["No. Months", "valid_month"],
    ["No. Weeks", "valid_week"],
    ["Term Pass", "term"],
    ["Max Attendances (e.g. first-timer)", "max_attendances"],
    ["Event Pass", "is_event"],
    ["Categories", "valid_category"],
    ["Presaleable (can be purchased without using)", "presaleable"],
    ["Available", "active"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    price: "money",
    valid_classes: "number",
    valid_month: "number",
    valid_week: "number",
    term: "boolean",
    max_attendances: "number",
    is_event: "boolean",
    valid_category: "categories",
    active: "boolean",
    presaleable: "boolean",
  };

  const formDefaults: PartialRecord<keyof TableType, FirebaseValueType> = {
    active: true,
  };

  const canDelete = false;
  const canAdd = true;
  const requiredFields = ["name", "code", "active"];
  const displayOrder = [
    orderBy("active", "desc"),
    orderBy("price", "desc"),
    orderBy("name", "desc"),
  ];

  const dbCollection = "pass-types";

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    formDefaults,
    requiredFields,
    search: true,
    searchIndexFields: ["name", "code"],
    canDelete,
    canAdd,
    displayOrder,
  });
}
