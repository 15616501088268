import { UserCredential } from "firebase/auth";
import { loginMethod } from "src/types";

// type loginMethod = "Google" | "Local";

const setLastLoginMethod = (method: loginMethod) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("lastLoginMethod", method);
  }
};

const postLogin = (result: UserCredential) => {
  // This gives you a Google Access Token. You can use it to access the Google API.
  // const credential = GoogleAuthProvider.credentialFromResult(result);
  // const token = credential!.accessToken;
  // The signed-in user info.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const user = result.user;
};

const postLoginGoogle = (result: UserCredential) => {
  setLastLoginMethod("Google");
  postLogin(result);
};

const postLoginLocal = (result: UserCredential) => {
  setLastLoginMethod("Local");
  postLogin(result);
};

export { postLoginGoogle, postLoginLocal };
