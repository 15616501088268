import { Link } from "preact-router";

interface ErrorProps {
  default?: boolean;
  type?: string;
  url?: string;
}
export const ErrorRoute = (props: ErrorProps) => {
  return (
    <div class="app-body-wrap">
      <div class="app-body">
        <h2>Error {props.type}</h2>
        <p>It looks like we hit a snag.</p>
        <pre>{props.url}</pre>
        <p>
          Try returning to the <Link href="/">Homepage</Link>
        </p>
      </div>
    </div>
  );
};
