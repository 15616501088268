import {
  ExtractTuples,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
  SourceMap,
} from "../import";
import { Discount } from "src/types";

const SQL_SCHEMA = `CREATE TABLE \`discounts\` (
  \`discount\` int(11) NOT NULL AUTO_INCREMENT,
  \`name\` varchar(40) NOT NULL,
  \`discount_flat\` decimal(9,2) DEFAULT '0.00',
  \`discount_mult\` decimal(9,2) DEFAULT '0.00',
  \`valid_limit\` int(11) DEFAULT NULL,
  \`available\` int(1) NOT NULL DEFAULT '1',
  \`valid_source\` int(11) DEFAULT NULL,
  \`max_attendances\` int(11) DEFAULT NULL,
  \`min_attendance_gap_months\` int(11) DEFAULT NULL,
  \`max_attendance_gap_months\` int(11) DEFAULT NULL,
  PRIMARY KEY (\`discount\`)
)`;

export const DISCOUNTS_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  name: "str",
  discount_flat: "str",
  discount_mult: "str",
  valid_limit: "int",
  active: "int",
  valid_source: "int",
  max_attendances: "int",
  min_attendance_gap_months: "int",
  max_attendance_gap_months: "int",
};

export const ExtractDiscounts = (input: string) => {
  SchemaCheck(input, SQL_SCHEMA);

  return ExtractTuples<Discount>(input, "discounts", DISCOUNTS_SCHEMA).map(
    (tuple) => {
      return {
        ...tuple,
        active: (tuple.active as unknown as number) !== 0,
        valid_source:
          SourceMap[tuple.valid_source as unknown as number] || null,
        discount_flat:
          (tuple.discount_flat &&
            parseFloat(tuple.discount_flat as unknown as string)) ||
          null,
        discount_mult:
          (tuple.discount_mult &&
            parseFloat(tuple.discount_mult as unknown as string)) ||
          null,
      };
    }
  );
};

export const ImportDiscounts = (input: string) =>
  SaveRecords(ExtractDiscounts(input), "discounts");
