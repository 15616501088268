import { DocumentReference, Timestamp, doc } from "firebase/firestore";
import { db } from "../firebase";

export const minTrue = (...values: number[]) => {
  return Math.min(...values.filter((value) => value > 0));
};

export const minTimestamp = (...values: Timestamp[]) => {
  let min: Timestamp | undefined;
  values.forEach((value) => {
    if (value && (!min || value < min)) {
      min = value;
    }
  });
  return min;
};

export const setUrlParam = (name: string, value?: string) => {
  const newSearch = new URLSearchParams(location.search);
  if (value) {
    newSearch.set(name, value);
  } else {
    newSearch.delete(name);
  }
  history.pushState(
    null,
    "",
    location.origin + location.pathname + "?" + newSearch.toString()
  );
};

export const filterRecord = <T>(
  records: Record<string, T>,
  pred: (record: [string, T]) => boolean
) => {
  return Object.fromEntries(Object.entries(records).filter(pred));
};

export const castDoc = <T>(path: string, ...pathSegments: string[]) => {
  return doc(db, path, ...pathSegments) as DocumentReference<T>;
};

export const partition = <T>(
  arr: T[],
  fn: (val: T, i?: number, arr?: T[]) => boolean
) =>
  arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val);
      return acc;
    },
    [[] as T[], [] as T[]]
  );
