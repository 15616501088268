import Profile from "../routes/profile";
import CMS from "../routes/cms";
import Teaching from "../routes/teaching";
import Door from "../routes/door";
import DoorPublic from "../routes/door/public";

import { claimKey } from "./claims";

export const ComponentTypeNames = [
  "Profile",
  "CMS",
  "Teaching",
  "Door",
  "DoorPublic",
  "Admin",
];

export type ComponentType = (arg0: RouteComponentProps) => JSX.Element;

export const Controllers: Record<string, ComponentType> = {
  DoorPublic: DoorPublic,
  CMS: CMS,
  Profile: Profile,
  Teaching: Teaching,
  Door: Door,
};

export interface RouteEntryComponent {
  title: string;
  component: ComponentType;
}

export interface RouteEntrySub {
  title: string;
  subRoute: RouteSet;
}

export type RouteEntry = RouteEntryComponent | RouteEntrySub;
export type RouteSet = Record<string, RouteEntry>;

export interface pageEntry {
  id?: string;
  url?: string;
  component: keyof typeof Controllers;
  title: string;
  body?: HTMLElement;
  menuOrder?: number;
  topLevelApp?: boolean;
  fullscreen?: boolean;
  role?: claimKey;
  data?: object;
}
export type pageList = Record<string, pageEntry>;
