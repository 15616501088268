import {
  CategoryMap,
  ExtractTuples,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
} from "../import";
import { PassType } from "src/types";
import { makeNGrams } from "../search";

const SQL_SCHEMA = `CREATE TABLE \`pass\` (
  \`pass\` int(11) NOT NULL AUTO_INCREMENT,
  \`name\` varchar(40) NOT NULL,
  \`code\` varchar(4) NOT NULL,
  \`price\` float DEFAULT NULL,
  \`valid_classes\` int(11) DEFAULT NULL,
  \`valid_month\` int(11) DEFAULT NULL,
  \`valid_people\` int(11) DEFAULT NULL,
  \`valid_event\` int(11) DEFAULT NULL,
  \`valid_category\` int(11) DEFAULT NULL,
  \`valid_type\` enum('class','workshop','private','danceparty') DEFAULT NULL,
  \`special\` int(1) NOT NULL,
  \`presaleable\` int(1) NOT NULL,
  \`valid_admin_only\` int(1) NOT NULL,
  \`available\` int(1) NOT NULL DEFAULT '1',
  \`term_pass\` int(1) DEFAULT '0',
  \`max_attendances\` int(11) DEFAULT NULL,
  \`valid_week\` int(11) DEFAULT NULL,
  PRIMARY KEY (\`pass\`)
)`;

export const PASS_TYPES_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  name: "str",
  code: "str",
  price: "float",
  valid_classes: "int",
  valid_month: "int",
  _valid_people: "int",
  _valid_event: "int",
  valid_category: "int",
  is_event: "str",
  _special: "int",
  presaleable: "int",
  _valid_admin_only: "int",
  active: "int",
  term: "int",
  max_attendances: "int",
  valid_week: "int",
};

const isMultiUse = (tuple: PassType) => {
  return (
    (tuple.valid_classes && tuple.valid_classes > 1) ||
    tuple.valid_month ||
    (tuple.valid_week && tuple.valid_week > 1)
  );
};

export const ExtractPassTypes = (input: string) => {
  SchemaCheck(input, SQL_SCHEMA);

  return ExtractTuples<PassType>(input, "pass", PASS_TYPES_SCHEMA).map(
    (tuple) => {
      return {
        ...tuple,
        presaleable: !!tuple.presaleable,
        multiuse: isMultiUse(tuple),
        term: !!tuple.term,
        active: !!tuple.active,
        is_event:
          tuple.is_event && (tuple.is_event as unknown as string) !== "class",
        valid_category:
          CategoryMap[tuple.valid_category as unknown as number] || null,
        searchKeywords: makeNGrams(
          ...["name", "code"].map((field) => (tuple as never)[field] as string)
        ),
      };
    }
  );
};

export const ImportPassTypes = (input: string) =>
  SaveRecords(ExtractPassTypes(input), "pass-types");
