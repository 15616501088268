import { orderBy } from "firebase/firestore";
import { PartialRecord, Term } from "src/types";
import FormPage, { TableFormType } from "../../../../components/forms/table";

export default function AdminTable() {
  type TableType = Term;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [["Name", "name"]];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {};

  const canDelete = false;
  const requiredFields = ["name"];

  const dbCollection = "terms";
  const displayOrder = [orderBy("name", "desc")];

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    displayOrder,
    canDelete,
  });
}
