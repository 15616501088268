import FormPage, {
  FormTab,
  FormTabRenderer,
  FormTabRendererProps,
  TableFormType,
} from "../../../../components/forms/table";
import { AdminEventsDefinition } from "./events";
import { AdminAttendance, AdminAttendanceSales } from "./attendance_base";

import { orderBy } from "firebase/firestore";
import { EventPrice, PartialRecord } from "../../../../types";

export const AdminEventPassDefinition = (): FormTabRenderer => {
  type TableType = EventPrice;

  const displayOrderFields: (keyof TableType)[] = ["full_price"];
  const displayOrder = displayOrderFields.map((field) =>
    orderBy(field, "desc")
  );
  const limit = 20;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Code", "code"],
    ["Available Until", "available_until"],
    ["Full Price", "full_price"],
    ["Concession Price", "concession"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    available_until: "datetime-local",
    full_price: "money",
    concession: "money",
  };

  const requiredFields = ["name", "code", "full_price"];

  return (props: FormTabRendererProps) => {
    const dbCollection = "events/" + props.instance?.id + "/passes/";

    return FormPage({
      dbCollection,
      formFields,
      formTypes,
      requiredFields,
      displayOrder,
      limit,
      canDelete: false,
    });
  };
};

export default function AdminEventSalesAttendance() {
  const tabs: FormTab = {
    Summary: null,
    Passes: AdminEventPassDefinition(),
    Sales: AdminAttendanceSales(),
    Attendance: AdminAttendance(),
  };

  const pageDefinition = {
    ...AdminEventsDefinition(),
    canDelete: false,
    list: true,
    tabs,
  };

  return FormPage(pageDefinition);
}
