import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { Link } from "preact-router";

interface popupMenuEntryBase {
  label: string;
  onClick?: () => void;
}

interface popupMenuEntryURL extends popupMenuEntryBase {
  url: string;
}
interface popupMenuEntrySub extends popupMenuEntryBase {
  subMenu: popupMenuEntry[];
}
export type popupMenuEntry = popupMenuEntryURL | popupMenuEntrySub;

interface Props {
  label: string;
  entries: popupMenuEntry[];
  onClose?: () => void;
}

export default function SubMenu({ label, entries, onClose }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: Event) => {
    setAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleClose = () => {
    onClose && onClose();
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Link activeClassName="active" onClick={handleClick} href={"#"}>
        {label}
      </Link>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {entries.map((entry) => (
          <MenuItem>
            {"url" in entry && (
              <Link
                activeClassName="active"
                href={entry.url}
                onClick={() => handleClose()}
              >
                {entry.label}
              </Link>
            )}
            {"subMenu" in entry && (
              <SubMenu
                label={entry.label}
                entries={entry.subMenu}
                onClose={handleClose}
              />
            )}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
