import { dateRangeFormat } from "../../utils/datetime";
import {
  ClassNight,
  DanceEvent,
  ExternalEvent,
  NewsletterContent,
  NewsletterContentBlock,
} from "../../types";
import {
  getDocs,
  query,
  collection,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { getCollection } from "../../utils/firebase";

export type Teaching = Record<string, string>;

export const getEventList = (): Promise<ExternalEvent[]> => {
  return new Promise((resolve) => {
    getCollection("locations").then((locationsArr) => {
      const locations = Object.fromEntries(
        locationsArr.map((entry) => [entry.id, entry.name])
      );
      Promise.all(
        ["events", "external-events"].map((collectionName) =>
          getDocs(
            query(
              collection(db, collectionName),
              where("is_published", "==", true),
              where("start", ">=", Timestamp.now())
            )
          )
        )
      ).then(([internal, external]) => {
        resolve(
          [
            ...internal.docs.map((doc) => {
              const data = doc.data() as DanceEvent;
              return {
                ...data,
                location: locations[data.location.id],
              } as ExternalEvent;
            }),
            ...external.docs.map((doc) => doc.data() as ExternalEvent),
          ].sort((a, b) => a.start.seconds - b.start.seconds)
        );
      });
    });
  });
};
export const teachingClassNight = (night: Partial<ClassNight>): Teaching => {
  return {
    ...(night.teachingBeginner ? { Beginner: night.teachingBeginner } : {}),
    ...(night.teachingFundamentals
      ? { Fundamentals: night.teachingFundamentals }
      : {}),
    ...(night.teachingVariations
      ? { Variations: night.teachingVariations }
      : {}),
  };
};
export const taughtClassNight = (night: Partial<ClassNight>): Teaching => {
  return {
    ...(night.taughtBeginner ? { Beginner: night.taughtBeginner } : {}),
    ...(night.taughtFundamentals
      ? { Fundamentals: night.taughtFundamentals }
      : {}),
    ...(night.taughtVariations ? { Variations: night.taughtVariations } : {}),
  };
};
const renderTeaching = (teaching?: Teaching) => {
  if (!teaching) {
    return "";
  }
  return (
    "<span style='font-weight: bold; color:#8c2b99; font-size:18px;'>Teaching This Week</span><br />" +
    ["Beginner", "Fundamentals", "Variations"]
      .filter((level) => Object.keys(teaching).includes(level))
      .map(
        (level) =>
          "<div><strong>" + level + ":</strong> " + teaching[level] + "</div>"
      )
      .join("") +
    "<hr style='margin-bottom:20px' />"
  );
};

const renderTaught = (taught?: Teaching) => {
  if (!taught) {
    return "";
  }
  return (
    "<span style='font-weight: bold; color:#8c2b99; font-size:18px;'>Last Week's Moves</span><br />" +
    ["Beginner", "Fundamentals", "Variations"]
      .filter((level) => Object.keys(taught).includes(level))
      .map(
        (level) =>
          "<div><strong>" + level + ":</strong> " + taught[level] + "</div>"
      )
      .join("") +
    "<hr style='margin-bottom:20px' />"
  );
};

const renderBlock = (block: NewsletterContentBlock) => {
  if (block.hidden) {
    return;
  }
  return (
    "<span style='font-weight: bold; color:#8c2b99; font-size:18px;'>" +
    block.title +
    "</span><br />" +
    block.body +
    "<hr style='margin-bottom:20px' />"
  );
};

const renderEvents = (events?: ExternalEvent[]) => {
  if (!events) {
    return "";
  }
  const renderEvent = (event: ExternalEvent) => {
    const name = () => "<b>" + event.name + "</b><br />";
    const location = () => event.location && event.location + "<br />";
    const when = () => dateRangeFormat(event.start, event.end) + "<br />";
    const description = () =>
      event.short_description && event.short_description + "<br />";
    return "<p>" + name() + when() + location() + description() + "</p>";
  };
  return (
    `<span style='font-weight: bold; color:#8c2b99; font-size:18px;'>Upcoming Events</span><br />
    <p>
      We have multiple parties & workshops locally each year; and for those who want a bit more, there are some competition & workshop weekends around Australia (always with plenty of social dancing as well)!
    </p>

    <p>
      If you'd like to know more please do chat to us about it!
      <br />
      If you're on facebook, also check out <a href="https://www.facebook.com/groups/906427319391648">https://www.facebook.com/groups/906427319391648</a> where you can keep up-to-date, discuss flights, and organise roomies
    </p>` +
    events.map(renderEvent).join("") +
    "<hr style='margin-bottom:20px' />"
  );
};

export const renderNewsletterBody = (
  blocks?: NewsletterContent[],
  teaching?: Teaching,
  taught?: Teaching,
  events?: ExternalEvent[]
) => {
  return (blocks || ["teaching", "events"])
    .map((block) =>
      block === "teaching"
        ? renderTeaching(teaching) + renderTaught(taught)
        : block === "events"
        ? renderEvents(events)
        : renderBlock(block)
    )
    .join("\n");
};
