import {
  ExtractTuples,
  ImportTimes,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
  timeToTimestamp,
} from "../import";
import { Attendance, DanceEvent, Pass, PassType, Sale } from "src/types";
import { DocumentReference, Timestamp, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { ExtractPassTypes } from "./pass";
import { SALES_SCHEMA } from "./sale";
import { ATTENDANCE_SCHEMA } from "./attendance";
import { EVENTS_SCHEMA } from "./event";

const SQL_SCHEMA = `CREATE TABLE \`entry\` (
  \`entry\` int(11) NOT NULL AUTO_INCREMENT,
  \`pass\` int(11) NOT NULL,
  \`remaining_uses\` int(11) DEFAULT NULL,
  \`expiry_date\` date DEFAULT NULL,
  \`valid_people\` int(11) DEFAULT NULL,
  \`valid_event\` int(11) DEFAULT NULL,
  \`valid_category\` int(11) DEFAULT NULL,
  \`valid_type\` enum('class','workshop','private','danceparty') DEFAULT NULL,
  \`valid_term\` int(11) DEFAULT NULL,
  PRIMARY KEY (\`entry\`),
  KEY \`valid_term_idx\` (\`valid_term\`)
)`;

export const ENTRIES_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  type: "int",
  remaining_uses: "int",
  expires: "date",
  _valid_people: "int",
  _valid_event: "int",
  _valid_category: "int",
  _valid_type: "str",
  _valid_term: "int",
};

export const ExtractEntry = (input: string, lastImports: ImportTimes) => {
  SchemaCheck(input, SQL_SCHEMA);

  const lastImport = lastImports["entry"] || 0;

  const passTypes = Object.fromEntries(
    ExtractPassTypes(input).map((tuple) => [tuple.id, tuple])
  );

  const sales = Object.fromEntries(
    ExtractTuples<Sale & { entry: number }>(input, "sale", SALES_SCHEMA).map(
      (tuple) => {
        return [tuple.entry, timeToTimestamp(tuple.time)];
      }
    )
  );

  const events: Record<string, Timestamp> = Object.fromEntries(
    ExtractTuples<DanceEvent>(input, "event", EVENTS_SCHEMA)
      .filter((tuple) => timeToTimestamp(tuple.start) >= lastImport)
      .map((tuple) => [tuple.id, timeToTimestamp(tuple.start)])
  );
  // through attendance to event.date
  const attendance = Object.fromEntries(
    ExtractTuples<Attendance & { entry: string; event: string }>(
      input,
      "attendance",
      ATTENDANCE_SCHEMA
    )
      .filter((tuple) => events[tuple.event as string])
      .map((tuple) => [tuple.entry, events[tuple.event as string]])
  );

  return ExtractTuples<Pass>(input, "entry", ENTRIES_SCHEMA)
    .map((tuple) => {
      return {
        ...tuple,
        expires: timeToTimestamp(tuple.expires),
        has_remaining_uses: tuple.remaining_uses > 0,
        puchased: sales[tuple.id || "unknown"],
        term: tuple.term ? doc(db, "/terms/" + tuple.term) : null,
        type: tuple.type
          ? (doc(
              db,
              "/pass-types/" + tuple.type
            ) as DocumentReference<PassType>)
          : null,
        name: tuple.type ? passTypes[tuple.type as unknown as number].name : "",
        code: tuple.type ? passTypes[tuple.type as unknown as number].code : "",
        pass_is_multiuse: tuple.type
          ? passTypes[tuple.type as unknown as number].multiuse
          : "",
      };
    })
    .filter(
      (tuple) =>
        tuple.puchased >= lastImport ||
        attendance[tuple.id as string] >= lastImport
    );
};

export const ImportEntry = (input: string, lastImports: ImportTimes) => {
  const sales = Object.fromEntries(
    ExtractTuples<Sale & { entry: number }>(input, "sale", SALES_SCHEMA).map(
      (tuple) => {
        return [tuple.entry, tuple.person];
      }
    )
  );

  const collectionResolver = (record: Pass) => {
    return "person/" + sales[record.id || "unknown"] + "/passes";
  };
  return SaveRecords<Pass>(
    ExtractEntry(input, lastImports),
    "entry",
    collectionResolver
  );
};
