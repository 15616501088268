import {
  FirebaseBackedObject,
  FirebaseValueType,
  PartialRecord,
  Stock,
} from "src/types";
import FormPage, { TableFormType } from "../../../components/forms/table";
import { orderBy } from "firebase/firestore";

export default function AdminStock() {
  type TableType = Stock;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Price", "price"],
    ["Barcode", "barcode"],
    ["Active", "active"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    price: "money",
    active: "boolean",
  };

  const formDefaults: PartialRecord<keyof TableType, FirebaseValueType> = {
    active: true,
  };

  const canDelete = false;
  const requiredFields = ["name", "active"];

  const displayOrderFields: (keyof TableType)[] = ["active", "price", "name"];
  const displayOrder = displayOrderFields.map((field) =>
    orderBy(field, "desc")
  );

  const searchIndexFields = ["name"];

  const dbCollection = "stock";

  const pagingOffset = (lastDoc: FirebaseBackedObject) => {
    return displayOrderFields.map((field) => (lastDoc as TableType)[field]);
  };

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    formDefaults,
    requiredFields,
    canDelete,
    list: true,
    pagingOffset,
    // search: true,
    searchIndexFields,
    displayOrder,
  });
}
