import { PartialRecord, PersonSource } from "src/types";
import FormPage, { TableFormType } from "../../../../components/forms/table";

export default function AdminSources() {
  type TableType = PersonSource;

  type formLine = [label: string, field: keyof TableType];
  const formFields: formLine[] = [
    ["Name", "name"],
    ["Has 20% discount", "source_discount"],
  ];

  const formTypes: PartialRecord<keyof TableType, TableFormType> = {
    source_discount: "boolean",
  };

  const canDelete = false;
  const requiredFields = ["name"];

  const dbCollection = "sources";

  return FormPage({
    dbCollection,
    formFields,
    formTypes,
    requiredFields,
    canDelete,
    limit: 100,
  });
}
