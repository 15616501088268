import { doc } from "firebase/firestore";
import {
  CollectionCache,
  ExtractTuples,
  ImportTimes,
  RE_TYPE_NAME,
  SaveRecords,
  SchemaCheck,
  timeToTimestamp,
} from "../import";
import { Sale, DanceEvent } from "src/types";
import { db } from "../../firebase";
import { ExtractDiscounts } from "./discounts";
import { EVENTS_SCHEMA } from "./event";
import { Timestamp } from "firebase-admin/firestore";
import { ExtractEntry } from "./entry";

const SQL_SCHEMA = `CREATE TABLE \`sale\` (
  \`sale\` int(11) NOT NULL AUTO_INCREMENT,
  \`date\` date NOT NULL,
  \`amount\` decimal(9,2) NOT NULL,
  \`method\` enum('cash','cheque','internet','voucher','square') DEFAULT NULL,
  \`person\` int(11) DEFAULT NULL,
  \`entry\` int(11) DEFAULT NULL,
  \`stock\` int(11) DEFAULT NULL,
  \`event\` int(11) DEFAULT NULL,
  \`discount\` int(11) DEFAULT NULL,
  PRIMARY KEY (\`sale\`),
  KEY \`person\` (\`person\`,\`event\`),
  KEY \`event\` (\`event\`)
)`;

export const SALES_SCHEMA: Record<string, RE_TYPE_NAME> = {
  id: "int",
  time: "date",
  amount: "str",
  method: "str",
  person: "int",
  entry: "int",
  lines: "int",
  event: "int",
  notes: "int",
};

export const ExtractSales = (
  input: string,
  lastImports: ImportTimes,
  collectionCache: CollectionCache
) => {
  SchemaCheck(input, SQL_SCHEMA);

  const lastImport = lastImports["sale"] || 0;

  const discounts = Object.fromEntries(
    ExtractDiscounts(input).map((tuple) => [tuple.id, tuple.name])
  );

  const entries = Object.fromEntries(
    ExtractEntry(input, lastImports).map((tuple) => [tuple.id, tuple])
  );

  if (!(collectionCache && "events" in collectionCache)) {
    throw "Failed to preload class night data";
  }

  const events = Object.fromEntries(
    ExtractTuples<DanceEvent>(input, "event", EVENTS_SCHEMA)
      .map((tuple) => [tuple.id, timeToTimestamp(tuple.start)])
      .filter((tuple) => tuple[1] && tuple[1] >= lastImport)
      .map((tuple) => [
        tuple[0],
        collectionCache["events"][
          (tuple[1] as Timestamp).toDate().toISOString().split("T")[0]
        ],
      ])
  );

  type SaleWithEntry = Sale & { entry: number };

  const makeSaleLine = (tuple: SaleWithEntry) => {
    if (tuple.lines) {
      return [{ quantity: 1, item: doc(db, "/stock/" + tuple.lines) }];
    }
    if (tuple.entry && entries[tuple.entry]) {
      return [
        {
          quantity: 1,
          item: entries[tuple.entry].type,
          pass: doc(
            db,
            "/person/" + entries[tuple.entry].person + "/passes/" + tuple.entry
          ),
        },
      ];
    }
    return null;
  };

  return (
    ExtractTuples<SaleWithEntry>(input, "sale", SALES_SCHEMA)
      .map((tuple) => {
        return {
          ...tuple,
          time: timeToTimestamp(tuple.time),
          event: events[tuple.event as unknown as number] || null,
          person: tuple.person ? doc(db, "/person/" + tuple.person) : null,
          lines: makeSaleLine(tuple),
          notes: tuple.notes ? "Discount: " + discounts[tuple.notes] : null,
          amount:
            (tuple.amount && parseFloat(tuple.amount as unknown as string)) ||
            null,
        };
      })
      // exclude purchases for $0 - that's staff/teacher entries, spectating, etc.
      // that's an attendance but not a sale
      .filter((tuple) => tuple.time >= lastImport && tuple.amount)
      .map((tuple) => {
        // remove entry; don't need it now that filtering is done
        const { entry: _, ...rest } = tuple;
        return rest;
      })
  );
};

export const ImportSales = (
  input: string,
  lastImports: ImportTimes,
  collectionCache: CollectionCache
) => {
  return SaveRecords(ExtractSales(input, lastImports, collectionCache), "sale");
};
