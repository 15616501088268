import { Button, Input } from "@mui/material";
import { useState } from "preact/hooks";
import { Pass } from "../../../types";
import { dateFormat, dateFormatAU } from "../../../utils/datetime";
import { TimestampMax } from "../../../utils/types";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

interface PersonPassesPanelProps {
  edit: boolean;
}

export function PersonPassesPanel(
  passes: Pass[],
  props?: PersonPassesPanelProps
) {
  const [editing, setEditing] = useState<Pass | undefined>();

  const status = (pass: Pass) => {
    if (
      pass.expires &&
      pass.expires.seconds > 0 &&
      pass.expires.toDate() < new Date()
    ) {
      return <span>Expired</span>;
    }
    if (pass.remaining_uses === 0) {
      return <span>Used</span>;
    }
    return <span class="active">Active</span>;
  };

  if (editing?.collectionPath) {
    const [newExpiryInput, setNewExpiryInput] = useState<string | undefined>(
      dateFormat(editing.expires)
    );
    const setExpiry = (expires: Timestamp) => {
      if (!editing.collectionPath) {
        return;
      }

      updateDoc(doc(db, editing.collectionPath), { expires })
        .then(() => {
          const updatedPass = passes.find(
            (pass) => pass.collectionPath === editing.collectionPath
          );
          if (updatedPass) {
            updatedPass.expires = expires;
          }
          setEditing(undefined);
        })
        .catch(alert);
    };
    return (
      <div>
        <p>Editing expiry of {editing.name}</p>
        <p>Old Expiry: {dateFormatAU(editing.expires)}</p>
        <p>
          <div>
            <Button onClick={() => setEditing(undefined)}>
              Cancel editing
            </Button>
          </div>
          <div>
            <Button onClick={() => setExpiry(TimestampMax)}>
              Clear Expiry
            </Button>
            (time will re-start when pass is next used)
          </div>
          <div>
            <form>
              <Input
                type="date"
                value={newExpiryInput}
                onInput={(e) =>
                  setNewExpiryInput((e.target as HTMLInputElement).value)
                }
              />
              <Button
                onClick={() =>
                  newExpiryInput &&
                  setExpiry(Timestamp.fromDate(new Date(newExpiryInput)))
                }
                disabled={newExpiryInput === dateFormat(editing.expires)}
              >
                Save
              </Button>
            </form>
          </div>
        </p>
      </div>
    );
  }

  const renderPass = (pass: Pass) => {
    const editable =
      props?.edit && pass.collectionPath && pass.has_remaining_uses;
    return (
      <tr>
        <td>
          {pass.name} ({pass.code})
        </td>
        <td>{dateFormatAU(pass.purchased)}</td>
        <td>{pass.remaining_uses}</td>
        <td>
          {dateFormatAU(pass.expires)}
          {editable && <Button onClick={() => setEditing(pass)}>extend</Button>}
        </td>
        <td>{status(pass)}</td>
      </tr>
    );
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Puchased</th>
          <th>Remaining Uses</th>
          <th>Expiry</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{passes.map(renderPass)}</tbody>
    </table>
  );
}
