import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import {
  Curriculum,
  CurriculumEntry,
  FirebaseValueType,
} from "../../../../types";
import { useEffect, useState } from "preact/hooks";
import { db } from "../../../../firebase";
import { Button, Input, Switch } from "@mui/material";

function CurriculumDisplay(initial: Curriculum) {
  const [curriculum, setCurriculum] = useState<
    Curriculum & { changed?: boolean }
  >(initial);

  const updateField = (field: string, value: FirebaseValueType) =>
    setCurriculum((current) => ({ ...current, changed: true, [field]: value }));

  const updateNightField = (night: number, field: string, value: string) => {
    setCurriculum((current) => {
      const nights = current.nights || [];
      nights[night] = { ...nights[night], [field]: value };
      return { ...current, changed: true, nights };
    });
  };

  const asRow = (name: string, input: JSX.Element) => {
    return (
      <tr>
        <th>{name}</th>
        <td>{input}</td>
      </tr>
    );
  };

  const addNight = (index: number) => {
    setCurriculum((current) => {
      const nights = current.nights || [];
      nights.splice(index, 0, {});
      return { ...current, changed: true, nights };
    });
  };

  const deleteNight = (index: number) => {
    setCurriculum((current) => {
      const nights = current.nights || [];
      nights.splice(index, 1);
      return { ...current, changed: true, nights };
    });
  };

  const moveNight = (from: number, to: number) => {
    setCurriculum((current) => {
      const nights = current.nights || [];
      nights.splice(to, 0, ...nights.splice(from, 1));
      return { ...current, changed: true, nights };
    });
  };

  const onSubmit = (e: Event) => {
    console.log("onsubmit", curriculum);
    if (curriculum.collectionPath) {
      updateDoc(doc(db, curriculum.collectionPath), { ...curriculum }).then(
        () => setCurriculum((current) => ({ ...current, changed: false }))
      );
    } else {
      addDoc(collection(db, "curriculums"), curriculum).then(() =>
        setCurriculum((current) => ({ ...current, changed: false }))
      );
    }
    e.preventDefault();
  };

  const resetForm = (e: Event) => {
    setCurriculum(initial);
    e.preventDefault();
  };

  const renderNight = (_night: CurriculumEntry, index: number) => {
    const label = "Night " + (index + 1).toString();

    const subForm = ["Beginner", "Fundamentals", "Variations"].map(
      (levelName) => {
        const level = levelName.toLowerCase() as keyof CurriculumEntry;
        const current =
          curriculum.nights &&
          curriculum.nights[index] &&
          curriculum.nights[index][level];

        return asRow(
          levelName,
          <Input
            key={index + "-" + level}
            fullWidth={true}
            value={current || ""}
            onChange={(e) =>
              updateNightField(
                index,
                level,
                (e.target as HTMLInputElement).value
              )
            }
          />
        );
      }
    );
    const controls = (
      <>
        {(curriculum.nights?.length || 0) > 1 && (
          <Button onClick={() => deleteNight(index)}>Delete Night</Button>
        )}
        <Button onClick={() => addNight(index + 1)}>Add Night</Button>
        {index > 0 && (
          <Button onClick={() => moveNight(index, index - 1)}>Move Up</Button>
        )}
        {index + 1 < (curriculum.nights?.length || 0) && (
          <Button onClick={() => moveNight(index, index + 1)}>Move Down</Button>
        )}
      </>
    );

    return asRow(
      label,
      <>
        <table>{subForm}</table>
        {controls}
      </>
    );
  };

  return (
    <>
      <br />
      <form onSubmit={onSubmit}>
        <table>
          {asRow(
            "Name",
            <Input
              value={curriculum.name}
              fullWidth={true}
              onChange={(e) =>
                updateField("name", (e.target as HTMLInputElement).value)
              }
            />
          )}
          {asRow(
            "Active",
            <Switch
              checked={curriculum.active}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateField("active", (e.target as HTMLInputElement).checked)
              }
            />
          )}
          {(curriculum.nights || [{}]).map(renderNight)}
        </table>
        <Button type="submit" disabled={!curriculum.changed}>
          Save
        </Button>
        <Button type="reset" onClick={resetForm}>
          Reset
        </Button>
      </form>
    </>
  );
}

export default function AdminCurriculum() {
  const [curriculums, setCurriculums] = useState<Record<string, Curriculum>>(
    {}
  );
  const [selected, setSelected] = useState<string>();

  const getCurriculums = () => {
    getDocs(query(collection(db, "curriculums"))).then((querySnapshot) => {
      setCurriculums(
        Object.fromEntries(
          querySnapshot.docs.map((snapDoc) => {
            const curriculum = {
              collectionPath: snapDoc.ref.path,
              id: snapDoc.id,
              ...snapDoc.data(),
            } as Curriculum;
            return [curriculum.collectionPath, curriculum];
          })
        )
      );
    });
  };

  useEffect(() => {
    getCurriculums();
  }, []);

  const curriculumList = () => {
    if (selected) {
      return (
        <>
          <Button onClick={() => setSelected(undefined)}>Back to list</Button>
          <br />
          {CurriculumDisplay(curriculums[selected])}
        </>
      );
    }
    return (
      <>
        <h2>Curriculums</h2>
        {Object.entries(curriculums).map(([path, curriculum]) => (
          <Button onClick={() => setSelected(path)}>
            {curriculum.name} ({curriculum.active ? "" : "in"}active)
          </Button>
        ))}
      </>
    );
  };
  return (
    <div class="app-body-wrap">
      <div class="app-body">{curriculumList()}</div>
    </div>
  );
}
