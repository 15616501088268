import { ExtractTuples, SaveRecords, SchemaCheck } from "../import";
import { Term } from "src/types";

const SQL_SCHEMA = `CREATE TABLE \`terms\` (
  \`term\` int(11) NOT NULL AUTO_INCREMENT,
  \`name\` varchar(40) NOT NULL,
  PRIMARY KEY (\`term\`),
  KEY \`name_idx\` (\`name\`)
)`;

export const ExtractTerms = (input: string) => {
  SchemaCheck(input, SQL_SCHEMA);

  return ExtractTuples<Term>(input, "terms", { id: "int", name: "str" });
};

export const ImportTerms = (input: string) =>
  SaveRecords(ExtractTerms(input), "terms");
