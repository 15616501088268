import { AttendanceSummary } from "src/types";

export function PersonClassAttendancesPanel(attendances: AttendanceSummary) {
  // TODO: counts
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Attended</th>
          <th>Payment Detail</th>
        </tr>
      </thead>
      <tbody>
        {attendances.recent.map((attendance) => (
          <tr>
            <td>{attendance.date?.toDate().toLocaleDateString()}</td>
            <td>{attendance.is_present ? "✓" : "✗"}</td>
            {attendance.pass_code ? (
              <td>
                {attendance.pass_name} ({attendance.pass_code})
              </td>
            ) : (
              <td class="payment-pending">Unpaid</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
