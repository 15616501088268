import { query, collection, getDocs, where } from "firebase/firestore";
import { db } from "../firebase";
import { FirebaseBackedObject } from "../types";
import {
  StorageReference,
  TaskState,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getPersonPasses } from "./firebase/person_passes";
import { getPersonAttendances } from "./firebase/person_attendance";

const collectionsCache: Record<string, Promise<FirebaseBackedObject[]>> = {};

const staticCollections = ["sources", "terms"]; // these don't have an active flag

export const normaliseCollectionName = (collectionName: string) => {
  // remove leading slash, trailing slash, doubled slashes
  return collectionName.replace(/^\/+|(?<=\/)\/+|\/+$/g, "");
};

export const getCollection = async (collectionName: string, all = false) => {
  const name = normaliseCollectionName(collectionName);
  const _query = () => {
    if (all || staticCollections.includes(name)) {
      return query(collection(db, name));
    }
    return query(collection(db, name), where("active", "==", true));
  };
  if (!(name in collectionsCache)) {
    collectionsCache[name] = getDocs(_query()).then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
          collectionPath: name + "/" + doc.id,
        };
      });
    });
  }

  return Promise.resolve(collectionsCache[name]);
};

export const allLocations = () => {
  return getCollection("locations");
};

export const uploadFile = (
  file?: File | null,
  fileRef?: StorageReference,
  callback?: (state: TaskState, progress?: number) => void
) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const storage = getStorage();
      fileRef = fileRef || ref(storage, file.name);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (callback) {
            callback(snapshot.state, progress);
          }
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          if (callback) {
            callback("error");
          }
          // Handle unsuccessful uploads
          reject(error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (callback) {
              callback("success");
            }
            console.log("File available at", downloadURL);
            resolve({
              default: downloadURL,
            });
          });
        }
      );
    } else {
      reject();
    }
  });
};

export type FirebaseBackedTypes =
  | "locations"
  | "sources"
  | "categories"
  | "pass-types"
  | "curriculums"
  | "terms";
// | "events"
// | "passes"
// | "person";
export const FirebaseBackedTypeNames = [
  "locations",
  "sources",
  "categories",
  "pass-types",
  "curriculums",
  "terms",
  // "events",
  // "passes",
  // "person",
];

export { getPersonPasses, getPersonAttendances };
