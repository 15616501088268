import { Link } from "preact-router/match";
import { loggedInUser, loginMethod } from "src/types";
import { postLoginGoogle, postLoginLocal } from "../../utils/auth";
import React, { useState } from "preact/compat";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  setPersistence,
  browserSessionPersistence,
  signInWithRedirect,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { VNode } from "preact";
import {
  Alert,
  Box,
  Input,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

interface TabPanelProps {
  children?: VNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface Props {
  currentUser?: loggedInUser;
  onClick?: () => void;
  asMobile?: boolean;
}

const persistLogin = true;

interface loginMethodDetails {
  label?: string;
  details: VNode;
}

export const Auth = ({ currentUser, onClick, asMobile }: Props) => {
  const auth = getAuth();
  const [open, setOpen] = useState(false);
  const lastMethod = (
    typeof localStorage !== "undefined"
      ? localStorage.getItem("lastLoginMethod")
      : null
  ) as loginMethod;
  const [showMethod, setShowMethod] = useState<loginMethod>(lastMethod);
  const [loginErr, setLoginErr] = useState<string | null>();

  const [localTabID, setLocalTabID] = React.useState(0);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(
    null
  );

  const handleSnackbarClose = (event?: Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage(null);
  };

  const handleLocalTabChange = (event: Event, newValue: number) => {
    setLocalTabID(newValue);
  };

  if (persistLogin) {
    setPersistence(
      auth,
      persistLogin ? browserLocalPersistence : browserSessionPersistence
    );
  }

  const signinAccount = (form: HTMLFormElement) => {
    const data = new FormData(form);
    const email = data.get("email") as string;
    const password = data.get("password") as string;

    signInWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        setClosed();
        postLoginLocal(credentials);
      })
      .catch((error) => {
        setLoginErr(error.message);
      });
  };

  const createAccount = (form: HTMLFormElement) => {
    const data = new FormData(form);
    const email = data.get("email") as string;
    const password = data.get("password") as string;
    const password2 = data.get("password2") as string;

    if (password !== password2) {
      setLoginErr("Passwords not the same");
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        setClosed();
        sendEmailVerification(credentials.user);
        setSnackbarMessage("Verification email sent, please check your email");
        postLoginLocal(credentials);
      })
      .catch((error) => {
        setLoginErr(error.message);
      });
  };

  const lostPassword = (form: HTMLFormElement) => {
    const data = new FormData(form);
    const email = data.get("email") as string;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSnackbarMessage("Reset link sent, please check your email");
        setOpen(false);
      })
      .catch((error) => {
        setLoginErr(error.message);
      });
  };

  const loginGoogle = () => {
    setClosed();
    const googleProvider = new GoogleAuthProvider();
    if (asMobile) {
      signInWithRedirect(auth, googleProvider);
    } else {
      signInWithPopup(auth, googleProvider).then(postLoginGoogle);
    }
  };

  const showLogin = (): boolean => {
    if (showMethod) {
      return true;
    }
    return (
      "search" in location &&
      !!location.search &&
      location.search.indexOf("login") > 0
    );
  };

  const localLoginForm = (
    label: string,
    callback: (form: HTMLFormElement) => void,
    passwordFields = 0
  ) => {
    return (
      <React.Fragment>
        <table>
          <tr>
            <td>Email Address:</td>
            <td>
              <Input required={true} type="email" name="email" />
            </td>
          </tr>
          {passwordFields > 0 && (
            <tr>
              <td>Password:</td>
              <td>
                <Input required={true} type="password" name="password" />
              </td>
            </tr>
          )}
          {passwordFields > 1 && (
            <tr>
              <td>Password again:</td>
              <td>
                <Input required={true} type="password" name="password2" />
              </td>
            </tr>
          )}
        </table>
        <DialogActions>
          <Button
            type="submit"
            onClick={(e: React.JSX.TargetedEvent<HTMLFormElement, Event>) =>
              callback(e.currentTarget.form)
            }
          >
            {label}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  };

  const loginMethods: Record<loginMethod, loginMethodDetails> = {
    Google: {
      details: (
        <div>
          Login using your Google account. We only see your name and email
          address.
          <DialogActions>
            <Button onClick={() => loginGoogle()} variant="outlined">
              Login using Google
            </Button>
          </DialogActions>
        </div>
      ),
    },
    Local: {
      label: "Website Account",
      details: (
        <form onSubmit={(e) => e.preventDefault()}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginTop: "-1em" }}
          >
            <Tabs
              value={localTabID}
              onChange={handleLocalTabChange}
              aria-label="Website Account"
            >
              <Tab label="Login" />
              <Tab label="Create Account" />
              <Tab label="Reset Password" />
            </Tabs>
          </Box>
          <TabPanel value={localTabID} index={0}>
            {localLoginForm("Login", signinAccount, 1)}
          </TabPanel>
          <TabPanel value={localTabID} index={1}>
            {localLoginForm("Create Account", createAccount, 2)}
          </TabPanel>
          <TabPanel value={localTabID} index={2}>
            {localLoginForm("Send Recovery Link", lostPassword)}
          </TabPanel>
          {loginErr && <Alert severity="error">{loginErr}</Alert>}
        </form>
      ),
    },
  };

  const setClosed = () => {
    setOpen(false);
    setLocalTabID(0);
    onClick && onClick();
  };

  const doLogout = () => {
    getAuth().signOut();
    onClick && onClick();
  };

  if (currentUser) {
    return <Link onClick={doLogout}>Log out</Link>;
  } else {
    if (!showLogin()) {
      return <React.Fragment />;
    }
    return (
      <React.Fragment>
        <Link
          onClick={() => {
            setLoginErr(null);
            setLocalTabID(0);
            setOpen(true);
          }}
        >
          Log in
        </Link>
        <Dialog open={open} onClose={setClosed}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ paddingBottom: 0 }}>
              <div>
                Current members may log in and prebook for upcoming events.
              </div>
              <br />
              <div>
                Please login using the email address as we have on record; i.e.
                where you get our newsletter.
                <br /> If you have not been receiving the newsletter, please
                contact us so we can update your details.
              </div>
            </DialogContentText>
            <DialogActions>
              {Object.keys(loginMethods).map((_key) => {
                const key = _key as loginMethod;
                return (
                  <Button
                    variant={showMethod === key ? "contained" : "outlined"}
                    onClick={() => {
                      setLoginErr(null);
                      setShowMethod(key);
                    }}
                  >
                    {loginMethods[key]["label"] || key}
                  </Button>
                );
              })}
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
          {showMethod && "details" in loginMethods[showMethod] && (
            <DialogContent sx={{ marginTop: "-1em" }}>
              <DialogContentText>
                {loginMethods[showMethod]["details"]}
              </DialogContentText>
            </DialogContent>
          )}
        </Dialog>
        <Snackbar
          open={!!snackbarMessage}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
};
