import {
  Switch,
  TextareaAutosize,
  Input,
  Button,
  SelectChangeEvent,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  limit,
  query,
  QueryConstraint,
  QueryOrderByConstraint,
  setDoc,
  startAfter,
  Timestamp,
  where,
} from "firebase/firestore";
import { Component } from "preact";
import { ChangeEvent, useEffect, useState } from "preact/compat";
import { db } from "../../firebase";
import { dateFormat, dateTimeLocalFormat } from "../../utils/datetime";
import {
  Curriculum,
  FirebaseBackedObject,
  FirebaseBackedSearchableObject,
  FirebaseValueType,
} from "src/types";
import style from "./style.scss";
import {
  FirebaseBackedTypeNames,
  FirebaseBackedTypes,
  getCollection,
} from "../../utils/firebase";
import {
  NGRAM_MIN_LENGTH,
  makeNGrams,
  searchPrepare,
} from "../../utils/search";
import { TabSet } from "./tabs";
import Mailcheck, { run } from "mailcheck";

Mailcheck.defaultTopLevelDomains.push(
  "edu.au",
  "org.au",
  "au",
  "asn.au",
  "id.au",
  "gov.au"
);
Mailcheck.defaultDomains.push("y7mail.com");

interface TableChanged {
  changed: boolean;
}

type formLine = [label: string, field: string];

type FormType = Record<string, TableFormType>;

export interface FormPageOptionsBase {
  formTypes: FormType;
  formFields?: formLine[];
  formDefaults?: Record<string, FirebaseValueType>;
  canDelete?: boolean;
  canAdd?: boolean;
  requiredFields?: string[];
  readOnlyFields?: string[];
}

export interface TabValue extends FormPageOptions {
  query?: () => void;
}

export interface FormTabRendererProps {
  props: FormPageOptions;
  instance?: FirebaseBackedObject;
  collections: Record<string, IdNamePair[]>;
}
export type FormTabRenderer = (props: FormTabRendererProps) => JSX.Element;

export type FormTab = Record<string, TabValue | FormTabRenderer | null>;

type IdNamePairProps = { disabled: boolean };
export type IdNamePair = [string, string | undefined, IdNamePairProps?];

export type CollectionsRecord = Record<string, IdNamePair[]>;

export type TableFormType =
  | "textarea"
  | "datetime-local"
  | "date"
  | "email"
  | "tel"
  | "boolean"
  | "textarea"
  | "string"
  | "number"
  | "money"
  | "path"
  | "url"
  | "order" // int in DB, order of subtables
  | string[] // for <select>
  | Record<string, FirebaseBackedObject>
  | IdNamePair[]
  | FirebaseBackedTypes
  | CollectionReference
  | FormPageOptionsBase;

function isFormTableSubForm(
  formLine: TableFormType
): formLine is FormPageOptionsBase {
  return (formLine as FormPageOptionsBase).formTypes !== undefined;
}

export type ExtraButtonType = (
  instance: FirebaseBackedObject | undefined,
  setValue: (field: string, value: FormFieldValue) => void,
  collections?: CollectionsRecord
) => void;

export interface FormPageOptions extends FormPageOptionsBase {
  dbCollection: string;
  displayOrder?: QueryOrderByConstraint[];
  limit?: number;
  queryConstraints?: (
    parentInstance?: FirebaseBackedObject
  ) => QueryConstraint[];
  readOnlyForm?: boolean;
  search?: boolean;
  list?: boolean;
  searchIndexFields?: string[];
  prepareSave?: (arg0: object) => object;
  tabs?: FormTab;
  nameFormatter?: (
    result: FirebaseBackedObject,
    collections?: CollectionsRecord
  ) => string;
  pagingOffset?: (lastDoc: FirebaseBackedObject) => FirebaseValueType[];
  extraButtons?: Record<string, ExtraButtonType>;
}

export interface FormTableProps extends FormPageOptions {
  instance?: object;
  collections?: Record<string, IdNamePair[]>;
  redraw?: () => void;
  postSave?: (instance: object) => void;
}

type FormTableState = TableChanged & FirebaseBackedObject;
type FormSubTableState = TableChanged & { id: string } & {
  [subField: string]: FirebaseBackedObject[];
};

type FormFieldValue =
  | string
  | number
  | boolean
  | string[]
  | Timestamp
  | DocumentReference
  | FirebaseBackedObject[]
  | undefined;

type SetFieldTarget = string | (string | number)[];

interface CurriculumSelectProps {
  curriculums: Curriculum[];
  value: string;
  offset: number;
  label: string;
  key: string;
  setter: (curriculum: string, offset: number) => void;
}

export const CurriculumSelect = (props: CurriculumSelectProps) => {
  const idJoin = " ";
  const currentCurriculum = props.curriculums.find(
    (curriculum) => curriculum.collectionPath === props.value
  );
  const offset =
    (props.offset || 0) % (currentCurriculum?.nights?.length || 10000);
  const selected = props.value ? props.value + idJoin + offset : "";
  const curriculumNights = props.curriculums.flatMap((curriculum) =>
    (curriculum.nights || []).map((night, index) => [
      curriculum.collectionPath + idJoin + index,
      `${curriculum.name}, week ${index + 1}: ${
        night.fundamentals
      } - ${night.variations?.substring(0, 30)}...`,
    ])
  );
  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const target = e.target as EventTarget & {
      value: string;
      name: string;
    };
    const [curriculum, curriculumOffset] = target.value.split(idJoin);
    props.setter(curriculum, parseInt(curriculumOffset));
  };
  return (
    <Select
      value={selected}
      onChange={onSelectChange}
      label={props.label}
      key={props.key}
    >
      <MenuItem value={""}>None</MenuItem>
      {curriculumNights.map(([id, name]) => (
        <MenuItem value={id} name={name}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export class FormTable extends Component<FormTableProps, FormTableState> {
  constructor(props: FormTableProps) {
    super();
    this.state = { ...props.formDefaults, ...props.instance, changed: false };
  }

  validateTelephone = (value: string) => {
    const stripped = value.replace(/\D/g, "");
    const expectLength = stripped.startsWith("0")
      ? 10
      : stripped.startsWith("61")
      ? 11
      : undefined;
    if (expectLength) {
      if (stripped.length < expectLength) {
        return "Too Short";
      }
      if (stripped.length > expectLength) {
        return "Too Long";
      }
    } else {
      return "Did not match Australian number";
    }
  };
  validateField = (field: string, inputType: TableFormType, value: string) => {
    if (typeof value !== "string") {
      return;
    }
    if (inputType === "tel") {
      return this.validateTelephone(value);
    }
    if (inputType === "email") {
      const suggestion = run({ email: value });
      if (suggestion) {
        return (
          <div>
            Possible typo - did you mean{" "}
            <Link onClick={() => this.setValue(field, suggestion.full)}>
              {suggestion.full}
            </Link>
          </div>
        );
      }
    }
  };

  reset = () => {
    // If this is an existing entry, reset to original values
    // Otherwise, reset each field to undefined
    const instance =
      this.props.instance ||
      Object.fromEntries(
        this.props.formFields?.map(([_, field]) => [field, undefined]) || []
      );
    this.setState({ ...instance, changed: false });
  };

  onsubmit = (e: Event) => {
    e.preventDefault();
    const objectData = this.props.prepareSave
      ? this.props.prepareSave(this.state)
      : this.state;
    if (this.props.search || this.props.searchIndexFields) {
      // create ngrams so it's searchable
      const searchIndexFields = this.props.searchIndexFields || ["name"];
      (objectData as FirebaseBackedSearchableObject).searchKeywords =
        makeNGrams(
          ...searchIndexFields.map(
            (field) => (objectData as never)[field] as string
          )
        );
      if ("barcode" in (objectData as FirebaseBackedSearchableObject)) {
        const barcode = (objectData as { barcode: string })["barcode"];
        if (barcode) {
          (objectData as FirebaseBackedSearchableObject).searchKeywords.push(
            barcode
          );
        }
      }
    }

    // any props.formTypes that are collections to be cast as Doc
    const collectionFields = Object.entries(this.props.formTypes)
      .filter(
        ([_, v]) => typeof v === "string" && FirebaseBackedTypeNames.includes(v)
      )
      .map(([k, _]) => k);

    collectionFields.forEach((collectionField) => {
      if (collectionField in objectData) {
        let value: DocumentReference<DocumentData> | FirebaseBackedTypes = (
          objectData as Record<string, FirebaseBackedTypes>
        )[collectionField];
        if (typeof value !== "object" || !("path" in value)) {
          value = doc(
            db,
            (objectData as Record<string, FirebaseBackedTypes>)[collectionField]
          );
        }
        (
          objectData as Record<
            string,
            FirebaseBackedTypes | DocumentReference<DocumentData>
          >
        )[collectionField] = value;
      }
    });

    if (this.state.id) {
      setDoc(doc(db, this.props.dbCollection, this.state.id), objectData, {
        merge: true,
      }).then(() => {
        this.setState({ changed: false });
        this.props.postSave && this.props.postSave(objectData);
      });
    } else {
      addDoc(collection(db, this.props.dbCollection), objectData).then(
        (docRef) => {
          this.setState({ changed: false, id: docRef.id });
          this.props.postSave &&
            this.props.postSave({ id: docRef.id, ...objectData });
          this.props.redraw && this.props.redraw();
          this.reset();
        }
      );
    }
  };

  delete = () => {
    if (confirm("Confirm deleting?")) {
      if (this.state.id) {
        deleteDoc(doc(db, this.props.dbCollection, this.state.id)).then(
          () => this.props.redraw && this.props.redraw()
        );
      }
    }
  };

  setValue = (field: SetFieldTarget, value: FormFieldValue) => {
    if (typeof field === "string") {
      if (value === undefined) {
        const { [field as keyof typeof this.state]: _removed, ...newState } =
          this.state;
        this.setState({ ...newState, changed: true });
      } else {
        this.setState({ [field]: value, changed: true });
      }
    } else {
      if (field.length !== 3) {
        throw new Error("Form doesn't support sub-sub-forms");
      }
      const newSubVal = (
        old: FormTableState,
        key: string,
        value: FormFieldValue
      ) => {
        if (value === undefined) {
          const { [key as keyof typeof old]: _removed, ...newState } = old;
          return { ...newState, changed: true };
        }
        return { ...old, [key]: value, changed: true };
      };
      const fieldName = field[0] as keyof typeof this.state;
      const index = field[1] as number;
      const subFieldName = field[2] as string;
      const oldVal = this.state[fieldName] as unknown as FormTableState[];
      const newVal = [
        ...oldVal.slice(0, index),
        newSubVal(oldVal[index], subFieldName, value),
        ...oldVal.slice(index + 1),
      ];
      this.setState({ [field[0]]: newVal, changed: true });
    }
  };

  setDatetimeLocalField = (field: SetFieldTarget, dateStr: string) => {
    if (!dateStr) {
      this.setValue(field, undefined);
    } else {
      const val = new Date(dateStr).getTime();
      const ts = new Timestamp(val / 1000, (val % 1000) * 1000000);
      this.setValue(field, ts);
    }
  };

  isType = (field: string, types: string[]) =>
    field in this.props.formTypes &&
    typeof this.props.formTypes[field] === "string" &&
    types.includes(this.props.formTypes[field] as string);

  isURIsh = (field: string) => {
    return this.isType(field, ["path", "url"]);
  };

  serialise = (value: string, field: string): FormFieldValue => {
    if (value === undefined) {
      return value;
    }
    if (this.isURIsh(field)) {
      return encodeURIComponent(value);
    }
    if (this.props.collections && field in this.props.collections) {
      return doc(db, value);
    }
    if (this.isType(field, ["money", "number"])) {
      if (value.includes(".")) {
        return parseFloat(value);
      }
      return parseInt(value);
    }

    // if type = "list", split on newlines into string[]
    return value;
  };

  deserialise = (value: FormFieldValue = "", field: string) => {
    if (this.isURIsh(field)) {
      return decodeURIComponent(value as string);
    }
    if (
      this.props.collections &&
      field in this.props.collections &&
      typeof value === "object" &&
      value !== null
    ) {
      return (value as DocumentReference).path;
    }
    return value;
    // if type is list; join array with newlines
  };

  inputWrap = (field: string) => {
    return this.input(field, field, this.state, this.props);
  };

  input = (
    field: string,
    updateTarget: SetFieldTarget,
    state: typeof this.state | FormSubTableState,
    props: typeof this.props
  ) => {
    const value =
      field in state
        ? this.deserialise(
            (state as Readonly<FormTableState>)[field as keyof typeof state],
            field
          )
        : undefined;
    const required = props.requiredFields?.includes(field);
    const disabled =
      props.readOnlyFields?.includes(field) || props.readOnlyForm;
    const key = state.id + "-" + field;

    const onSelectChange = (e: SelectChangeEvent<string>) => {
      const target = e.target as EventTarget & { value: string; name: string };
      this.setValue(updateTarget, this.serialise(target.value, field));
    };

    const onChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      this.setValue(updateTarget, this.serialise(e.currentTarget.value, field));
    };

    if (field in props.formTypes) {
      if (isFormTableSubForm(props.formTypes[field])) {
        const subFormArr = (state as FormSubTableState)[
          field as keyof typeof state
        ] as FirebaseBackedObject[];
        const subFormDefinition: FormPageOptionsBase = props.formTypes[
          field
        ] as FormPageOptionsBase;

        const addSubTable = () => {
          this.setValue(field, [...subFormArr, {}]);
        };
        const deleteSubTable = (index: number) => {
          if (confirm("Confirm deleting?")) {
            this.setValue(field, [
              ...subFormArr.slice(0, index),
              ...subFormArr.slice(index + 1),
            ]);
          }
        };

        return (
          <>
            {subFormArr?.map((subFormEntry, index) => (
              <>
                <table class={style.formTable} style={{ marginBottom: "0" }}>
                  {subFormDefinition.formFields?.map(([label, subField]) => {
                    const subState: typeof this.state = {
                      changed: false,
                      id: field + "-" + index.toString(),
                      ...subFormEntry,
                    };
                    const subProps: typeof this.props = {
                      ...props,
                      ...subFormDefinition,
                    };
                    const target = [field, index, subField];

                    return (
                      <tr>
                        <td>{label}</td>
                        <td>
                          {this.input(subField, target, subState, subProps)}
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <Button
                  style={{ display: "block", marginLeft: "auto" }}
                  onClick={() => deleteSubTable(index)}
                >
                  Remove
                </Button>
              </>
            ))}

            <Button onClick={addSubTable}>Add Entry</Button>
          </>
        );
      }

      if (props.formTypes[field] == "date") {
        return (
          <Input
            type={props.formTypes[field] as string}
            value={dateFormat(value as unknown as Timestamp)}
            onChange={(e) =>
              this.setDatetimeLocalField(updateTarget, e.currentTarget.value)
            }
            required={required}
            disabled={disabled}
            key={key}
          />
        );
      }

      if (props.formTypes[field] == "datetime-local") {
        return (
          <Input
            type={props.formTypes[field] as string}
            value={dateTimeLocalFormat(value as unknown as Timestamp)}
            onChange={(e) =>
              this.setDatetimeLocalField(updateTarget, e.currentTarget.value)
            }
            required={required}
            disabled={disabled}
            key={key}
          />
        );
      }

      if (props.formTypes[field] == "boolean") {
        if (required && typeof value === "undefined") {
          this.setValue(updateTarget, false);
        }
        return (
          <Switch
            checked={!!value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              this.setValue(updateTarget, e.currentTarget.checked)
            }
            disabled={disabled}
            key={key}
          />
        );
      }

      if (props.formTypes[field] === "textarea") {
        return (
          <TextareaAutosize
            minRows={5}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            key={key}
          />
        );
      }

      if (
        props.formTypes[field] === "curriculums" &&
        props.collections &&
        field in props.collections
      ) {
        const curriculums = props.collections[field].map(
          ([_, entry]) => entry
        ) as Curriculum[];
        const offset = (state as unknown as { curriculumOffset: number })[
          "curriculumOffset"
        ];

        const setter = (curriculum: string, curriculumOffset: number) => {
          this.setState((state) => ({
            ...state,
            changed: true,
            [field]: curriculum,
            curriculumOffset,
          }));
        };

        const selectProps = {
          curriculums,
          setter,
          value: value as string,
          key,
          offset,
          label: field,
        };

        return <CurriculumSelect {...selectProps} />;
      }

      if (props.collections && field in props.collections) {
        const selected =
          (value as string) ||
          (required &&
          props.collections[field] &&
          props.collections[field][0] &&
          props.collections[field][0][0]
            ? props.collections[field][0][0]
            : "");
        const selectDisabled =
          disabled ||
          props.collections[field].find((value) => value[0] === selected)?.[2]
            ?.disabled;
        if (
          required &&
          (typeof value === "undefined" || value === "") &&
          typeof selected !== "undefined"
        ) {
          this.setValue(updateTarget, selected);
        }
        return (
          <Select
            value={selected}
            onChange={onSelectChange}
            label={field}
            disabled={selectDisabled}
            key={key}
          >
            {!required && <MenuItem value={""}>None</MenuItem>}
            {props.collections[field].map(([id, name, props]) => (
              <MenuItem value={id} name={name} disabled={props?.disabled}>
                {name}
              </MenuItem>
            ))}
          </Select>
        );
      }

      if (Array.isArray(props.formTypes[field])) {
        if (required && typeof value === "undefined") {
          this.setValue(updateTarget, (props.formTypes[field] as string[])[0]);
        }
        return (
          <Select
            value={value as string}
            onChange={onSelectChange}
            label={field}
            disabled={disabled}
            key={key}
          >
            {!required && <MenuItem value={""}>None</MenuItem>}
            {(props.formTypes[field] as string[]).map((option) => (
              <MenuItem value={option} name={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      }

      // TODO: if type==="photos"
      // render sub-form as array of URL & description
      // Or similarly for anything else that has one-to-many
    }
    if (required && typeof value === "undefined") {
      this.setValue(updateTarget, "");
    }

    const inputType = props.formTypes[field];
    const errMsg =
      !disabled &&
      typeof value === "string" &&
      this.validateField(field, inputType, value);

    const inputProps = {
      type: (inputType as string) || "text",
      value,
      onChange,
      required,
      disabled,
      error: !!errMsg,
      inputProps: inputType === "money" ? { step: "0.01" } : undefined,
      key,
    };
    if (inputType === "money") {
      inputProps["type"] = "number";
    }

    return (
      <>
        <Input {...inputProps} />
        {errMsg}
      </>
    );
  };

  render() {
    return (
      <form onSubmit={this.onsubmit}>
        <input type="hidden" value={this.state?.id} />
        <table class={style.formTable}>
          {this.props.formFields?.map(([label, field]) => (
            <tr>
              <td>{label}</td>
              <td>{this.inputWrap(field)}</td>
            </tr>
          ))}
          {this.props.readOnlyForm || (
            <tr>
              <td></td>
              <td>
                <Button type="submit" disabled={!this.state.changed}>
                  {this.props.instance ? "Save" : "Create"}
                </Button>
                <Button onClick={this.reset} disabled={!this.state.changed}>
                  Reset
                </Button>
                {this.props.extraButtons &&
                  Object.keys(this.props.extraButtons).map((btn) => (
                    <Button
                      onClick={() =>
                        this.props.extraButtons &&
                        this.props.extraButtons[btn](
                          this.state,
                          this.setValue,
                          this.props.collections
                        )
                      }
                    >
                      {btn}
                    </Button>
                  ))}
                {this.props.canDelete && this.state.id && (
                  <Button onClick={this.delete}>Delete</Button>
                )}
              </td>
            </tr>
          )}
        </table>
      </form>
    );
  }
}

function SearchableFormPageContent(props: FormPageOptions) {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [searchTerm, _setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<
    FirebaseBackedSearchableObject[]
  >([]);

  const [instance, _setInstance] = useState<object | undefined>(undefined);

  const [collections, _setCollections] = useState<Record<string, IdNamePair[]>>(
    {}
  );
  const [tabInstances, setTabInstances] = useState<Record<number, object[]>>(
    []
  );

  const setCollections = (newCollections: Record<string, IdNamePair[]>) => {
    _setCollections({ ...collections, ...newCollections });
  };

  const setInstance = (instance?: object) => {
    const newSearch = new URLSearchParams(location.search);
    if (instance) {
      newSearch.set("docId", (instance as never)["id"]);
    } else {
      newSearch.delete("docId");
    }
    history.pushState(
      null,
      "",
      location.origin + location.pathname + "?" + newSearch.toString()
    );

    if (instance && props.tabs) {
      Object.values(props.tabs).forEach((tab, index) => {
        if (tab) {
          const setInstances = (instances: object[]) => {
            setTabInstances({ ...tabInstances, [index]: instances });
          };
          if (typeof tab !== "function") {
            runQuery(tab, setInstances, setCollections, instance);
          }
        }
      });
    }

    _setInstance(instance);
  };

  const loadCollections = () => {
    const collectionFields = Object.keys(props.formTypes).filter((typeName) =>
      FirebaseBackedTypeNames.includes(props.formTypes[typeName] as string)
    );
    const collectionsQueries = collectionFields.map((typeName) => {
      return getCollection(props.formTypes[typeName] as string);
    });

    Promise.all(collectionsQueries).then((resultList) => {
      setCollections(
        Object.fromEntries(
          (resultList as FirebaseBackedObject[][]).map(
            (entry: FirebaseBackedObject[], index: number) => {
              if (collectionFields[index] === "curriculum") {
                return [
                  collectionFields[index],
                  entry.map((entry) => [entry.collectionPath, entry]),
                ];
              }

              return [
                collectionFields[index],
                entry.map(
                  (entry) => [entry.collectionPath, entry.name] as IdNamePair
                ),
              ];
            }
          )
        )
      );
    });
  };

  useEffect(() => {
    const initialDocId = new URLSearchParams(location.search).get("docId");
    if (initialDocId && !instance) {
      getDoc(doc(db, props.dbCollection, initialDocId)).then((docSnap) => {
        if (docSnap.exists()) {
          setInstance({ id: docSnap.id, ...docSnap.data() });
        }
      });
    }
    if (props.list) {
      search();
    }
    loadCollections();
  }, []);

  if (showCreate || instance) {
    const renderInstance = () => {
      if (props.tabs) {
        const tabPanel = (
          tab: TabValue | FormTabRenderer | null,
          index: number
        ) => {
          if (typeof tab === "function") {
            return tab({ instance, props, collections });
          }
          const instanceSet = tabInstances[index] || [instance];
          const tabProps = tab || props;
          return (
            <>
              {!tab || tab.canAdd === false || (
                <FormTable {...tabProps} collections={collections} />
              )}

              {instanceSet.map((tabInstance) => (
                <FormTable
                  {...tabProps}
                  instance={tabInstance}
                  collections={collections}
                />
              ))}
            </>
          );
        };

        const tabs = Object.fromEntries(
          Object.entries(props.tabs).map(([name, value], index) => {
            return [name, tabPanel(value, index)];
          })
        );

        return <TabSet tabs={tabs} />;
      } else {
        return (
          <FormTable {...props} instance={instance} collections={collections} />
        );
      }
    };
    return (
      <>
        <div>
          <button
            onClick={() => {
              setShowCreate(false);
              setInstance(undefined);
            }}
          >
            Back
          </button>
        </div>
        {renderInstance()}
      </>
    );
  }

  const search = (term?: string, append?: boolean) => {
    const lastResultOrderBy =
      append && searchResults.length && props.pagingOffset
        ? props.pagingOffset(searchResults[searchResults.length - 1])
        : [];

    const queryConstraints = [
      ...(term ? [where("searchKeywords", "array-contains", term)] : []),
      ...(props.displayOrder ? props.displayOrder : []),
      ...(lastResultOrderBy.length > 0
        ? [startAfter(...lastResultOrderBy)]
        : []),
      limit(props.limit || 10),
    ];

    const primaryQuery = query(
      collection(db, props.dbCollection),
      ...queryConstraints
    );

    getDocs(primaryQuery).then((resultList) => {
      const results = resultList.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as FirebaseBackedSearchableObject;
      });
      if (append) {
        setSearchResults([...searchResults, ...results]);
      } else {
        setSearchResults(results);
      }
    });
  };

  const setSearchTerm = (term: string) => {
    const preparedTerm = searchPrepare(term);
    if (preparedTerm.length >= NGRAM_MIN_LENGTH) {
      search(preparedTerm);
    } else {
      setSearchResults([]);
    }
    _setSearchTerm(term);
  };

  return (
    <div>
      {props.canAdd && (
        <div class={style.row}>
          <button onClick={() => setShowCreate(true)}>Create new entry</button>
        </div>
      )}
      {props.search && (
        <input
          class={style.row}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          placeholder={"Search by name"}
        />
      )}
      {searchResults.map((result) => {
        const docId = result.id;
        if (docId) {
          const onClick = () => {
            getDoc(doc(db, props.dbCollection, docId)).then((docSnap) => {
              if (docSnap.exists()) {
                const doc = { id: docId, ...docSnap.data() };
                setInstance(doc);
                _setSearchTerm("");
              }
            });
          };
          return (
            <div class={style.row}>
              <button onClick={onClick}>
                {props.nameFormatter
                  ? props.nameFormatter(result, collections)
                  : result.name}
              </button>
            </div>
          );
        }
      })}
      {props.list && (
        <button onClick={() => search(undefined, true)}>Load More</button>
      )}
    </div>
  );
}

const runQuery = (
  props: FormPageOptions,
  setInstances: (instances: object[]) => void,
  setCollections: (instances: Record<string, IdNamePair[]>) => void,
  parentInstance?: FirebaseBackedObject
) => {
  setInstances([]);
  const queryConstraints = [
    ...(props.displayOrder ? props.displayOrder : []),
    limit(props.limit || 10),
  ];

  const dbCollection =
    parentInstance && parentInstance.id
      ? props.dbCollection.replace("{id}", parentInstance.id)
      : props.dbCollection;

  const primaryQuery = query(collection(db, dbCollection), ...queryConstraints);

  const collectionFields = Object.keys(props.formTypes).filter((typeName) =>
    FirebaseBackedTypeNames.includes(props.formTypes[typeName] as string)
  );
  const collectionsQueries = collectionFields.map((typeName) => {
    return getCollection(props.formTypes[typeName] as string);
  });

  Promise.all([getDocs(primaryQuery), ...collectionsQueries]).then(
    (resultList) => {
      setInstances(
        resultList[0].docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        })
      );
      setCollections(
        Object.fromEntries(
          (resultList.slice(1) as FirebaseBackedObject[][]).map(
            (entry: FirebaseBackedObject[], index: number) => {
              if (collectionFields[index] === "curriculum") {
                return [
                  collectionFields[index],
                  entry.map((entry) => [entry.collectionPath, entry]),
                ];
              }
              return [
                collectionFields[index],
                entry.map(
                  (entry) => [entry.collectionPath, entry.name] as IdNamePair
                ),
              ];
            }
          )
        )
      );
    }
  );
};

export default function FormPage(props: FormPageOptions) {
  if (props.search || props.list) {
    return (
      <div class="app-body-wrap">
        <div class="app-body">{SearchableFormPageContent(props)}</div>
      </div>
    );
  }
  const [instances, setInstances] = useState<object[]>();

  const [collections, setCollections] = useState<Record<string, IdNamePair[]>>(
    {}
  );

  const update = () => {
    runQuery(props, setInstances, setCollections);
  };

  useEffect(() => {
    update();
  }, []);

  if (!(collections && instances?.length)) {
    return <></>;
  }

  const newFormProps = () => {
    if (
      instances[0] &&
      "curriculum" in instances[0] &&
      instances[0]["curriculum"] &&
      "curriculumOffset" in instances[0] &&
      instances[0]["curriculumOffset"]
    ) {
      return {
        ...props,
        formDefaults: {
          ...props.formDefaults,
          curriculum: instances[0]["curriculum"] as FirebaseValueType,
          curriculumOffset: (instances[0]["curriculumOffset"] as number) + 1,
        },
      } as typeof props;
    }
    return props;
  };

  return (
    <div class="app-body-wrap">
      <div class="app-body">
        {props.canAdd === false || (
          <FormTable
            redraw={update}
            {...newFormProps()}
            collections={collections}
          />
        )}
        {instances.length > 0 &&
          instances.map((instance) => (
            <FormTable
              instance={instance}
              redraw={update}
              {...props}
              collections={collections}
            />
          ))}
      </div>
    </div>
  );
}
