import Quagga from "@ericblade/quagga2";

export interface CameraSelectFormProps {
  cameraError: string | undefined;
  cameras: MediaDeviceInfo[];
  setCameraId: (value: string) => void;
}

export const CameraSelectForm = (props: CameraSelectFormProps) => {
  if (props.cameraError) {
    return (
      <p>
        Unable to use camera - do you have a camera with permission granted?
        <br />
        {props.cameraError}
      </p>
    );
  }
  if (props.cameras.length === 0) {
    return <p>Checking for cameras - browser may prompt for permissions</p>;
  }
  if (props.cameras.length > 1) {
    return (
      <form>
        <select
          onChange={(event) => props.setCameraId(event.currentTarget?.value)}
        >
          {props.cameras.map((camera) => (
            <option key={camera.deviceId} value={camera.deviceId}>
              {camera.label || camera.deviceId}
            </option>
          ))}
        </select>
      </form>
    );
  }
  return <></>;
};

export interface InitScannerProps {
  setCameras: (value: MediaDeviceInfo[]) => void;
  setCameraId: (value: string) => void;
  setCameraError: (value: string) => void;
  startScanning?: () => void;
}

export const initScanner = (props: InitScannerProps) => {
  const enableCamera = () => Quagga.CameraAccess.request(null, {});
  const disableCamera = () => Quagga.CameraAccess.release();
  const enumerateCameras = () => Quagga.CameraAccess.enumerateVideoDevices();

  enableCamera()
    .then(disableCamera)
    .then(enumerateCameras)
    .then((cameras) => {
      props.setCameras(cameras);
      // TODO: if multiple cameras, e.g. front&back on tablet, use a default?
      if (cameras.length === 1) {
        props.setCameraId(cameras[0].deviceId);
        props.startScanning && props.startScanning();
      }
    })
    .catch((err) => props.setCameraError(err.message));

  return disableCamera;
};
