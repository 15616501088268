import style from "./style.scss";
import {
  collection,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import React, { useState, useEffect } from "react";

import { Announcement } from "../../types";

export const Announcements = () => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const now = Timestamp.fromDate(new Date());

  useEffect(() => {
    const q = query(
      collection(db, "announcements"),
      where("on_website", "==", true),
      where("show_until", ">=", now)
    );
    getDocs(q).then((querySnapshot) => {
      setAnnouncements(
        querySnapshot.docs.map((doc) => doc.data() as Announcement)
      );
    });
  }, []);

  // TODO: styling

  return (
    <React.Fragment>
      {announcements.map((announcement) => (
        <div class={style.announcement}>
          <strong>{announcement.title}</strong>
          {announcement.body}
        </div>
      ))}
    </React.Fragment>
  );
};
